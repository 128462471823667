@import '../../../../styles/bootstrap-variables';
@import '../../../../styles/_colors';

.teamMemberItem {
  background-color: $sl-aliceblue-tint;
  padding: 16px;
  margin-bottom: 12px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__actions {
      color: $sl-ocean-blue;
    }
  }

  &__body {
    padding-top: 4px;
  }

  &__info {
    margin: 4px 0px;
    display: flex;
    align-items: center;
  }

  &__label {
    color: $sl-gray-label !important;
    padding: 4px 6px 4px 0px;
  }

  &__details {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__expanded {
    margin-top: 16px;
  }
}

.teamMemberList {
  overflow-y: auto;
  height: calc(100vh - 12.625rem);
  background-color: white;
  margin: 0px 16px;

  .teamMemberItem {
    background: $sl-aliceblue-tint;
    box-shadow: 0px 2px 12px 0px rgba(27, 29, 92, 0.16);
    margin-bottom: 16px;

    &__header,
    &__info {
      span {
        font-weight: 500;
        color: $sl-dark-grey-mobile;
      }
    }
  }
}
