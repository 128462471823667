@use "sass:math";

.form-text {
  font-size: math.div(1rem * 13px, $font-size-nominal-default);
  color: #737373;
}

.custom-control-input.is-valid,
.custom-control-input.is-invalid {
  ~ .custom-control-label {
    color: inherit;
  }
}

input.bg-transparent {
  background: transparent;
  box-shadow: none;
  border: none;
  text-align: center;
}

.btn, .form-control {
  border-radius: 4px !important;
}

.btn-outline-primary,
.btn-outline-secondary {
  &:disabled,
  &.disabled {
    &,
    &:hover {
      color: #a2a2a2;
      border-color: #a2a2a2;
      background-color: transparent;
    }
  }
}

.btn-outline-secondary {
  &:hover {
    background-color: transparent;
    color: $sl-coral;
    border-color: $sl-coral;
  }
  &:focus {
    border-color: $sl-blood-orange;
    color: $sl-coral;
    box-shadow: none;
  }

  &.filled {
    background-color: white;
  }
}

.btn-secondary {
  &:hover {
    background-color: $sl-coral;
    border-color: $sl-coral;
  }
  &:focus {
    background-color: $sl-coral;
    border-color: $sl-blood-orange;
    box-shadow: none;
  }
  &:disabled,
  &.disabled,
  &:disabled:hover,
  &.disabled {
    background-color: #a2a2a2;
    border-color: #a2a2a2;
  }
}

.btn-link {
  &:focus {
    border-color: currentColor;
  }
}

.btn-link-secondary {
  color: $sl-blood-orange;
  &:hover {
    color: $sl-coral;
    text-decoration: underline;
  }
}

.btn-std-width {
  min-width: 9.5em;
}

.table {
  border-collapse: separate;
  border-spacing: 0;

  thead th {
    border-bottom-style: none;
    border-top-style: none;
    min-width: 90px;

    .btn.btn-link {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  th,
  td {
    border-top-style: none;
    vertical-align: middle;
  }

  tbody td {
    border-radius: 0;
    border: 1px none $sl-light-blue;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  tbody tr.isEven td,
  tbody tr.isEven + .expansion td {
    border-color: $sl-aliceblue;
  }

  tbody tr {
    &.isExpanded td {
      border-top-style: solid;
      padding-top: calc(#{$table-cell-padding} - 1px);

      &:first-child {
        border-bottom-left-radius: 0;
        border-left-style: solid;
        padding-left: calc(#{$table-cell-padding} - 1px);
      }
      &:last-child {
        border-bottom-right-radius: 0;
        border-right-style: solid;
        padding-right: calc(#{$table-cell-padding} - 1px);
      }
    }

    &.expansion td {
      border-style: solid;
      border-top-left-radius: 0!important;
      border-top-right-radius: 0!important;
      border-top-style: none;
      padding-left: calc(#{$table-cell-padding} - 1px);
      padding-right: calc(#{$table-cell-padding} - 1px);
      padding-bottom: calc(#{$table-cell-padding} - 1px);
    }
  }
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: initial;

  > td {
    background-color: $table-accent-bg;
  }
}

// Tables with dropdown rows can't use the regular striped style and
// specifically call out on rows whether they're to be styled as if odd or even
.table tbody tr.isOdd td {
  background-color: $table-accent-bg;
}

.btn-unstyled,
.btn-unstyled:hover,
.btn-unstyled:active,
.btn-unstyled:focus {
  color: inherit;
  padding: 0;
  border: none;
}

.modal-dialog {
  @include media-breakpoint-up(lg) {
    .modal-md {
      max-width: 625px;
    }
  }
}

.modal-content {
  border-style: none;
  box-shadow: 0 2px 4px 0 $sl-shadow;
}

.modal-header {
  align-items: left;
  display: block;
  border-bottom-style: none;
  padding-bottom: 0;

  .modal-lg &,
  .modal-md & {
    padding-top: 3rem;
  }

  .close {
    // Close icon text is added by react-bootstrap; we don't want it
    overflow: hidden;
    text-indent: -100vw;
    color: transparent;

    // Add icon background instead
    background-image: url('../shared/icons/close-icon.svg');
    background-size: 11px;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: content-box;
    width: 11px;
    height: 11px;

    position: absolute;
    top: 0;
    right: 0;
    padding: $spacer * 1.5;
    margin: 0;
  }

  .modal-title {
    font-size: 1.5rem;
  }
}

.wide-modal.modal {
  .modal-lg {
    max-width: 960px;
  }
}

.modal-header,
.modal-body,
.modal-footer {
  .modal-lg &,
  .modal-md & {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.modal-footer {
  border-top-style: none;
  padding-top: 0;

  .modal-lg & {
    padding-bottom: $spacer * 3;
  }

  .modal-md & {
    padding-top: $spacer;
    padding-bottom: $spacer * 4;
  }
}

.dropdown-menu {
  box-shadow: 0 2px 4px $sl-shadow;
  padding: 0;
  overflow-x: hidden;
  border-radius: 8px;
}

.dropdown-toggle {
  border-color: #a2a2a2;
  font-weight: inherit;

  &.no-outline {
    border-color: transparent;
  }

  .dropdown.show &.with-background,
  &.with-background:focus {
    background-color: #e0eff4;
    border-color: transparent;
  }

  .dropdown.show &,
  &:focus {
    border-color: $sl-ocean-blue;
  }

  &.with-heavy-text {
    font-weight: 500;
    color: $sl-ocean-blue;
  }

  &.slightly-shorter {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.actions-dropdown {
  max-width: 150px;
}

.dropdown-toggle::after {
  display: none;
  // We add an SVG icon to these in the DropdownToggle component
}

.dropdown-header:not(:first-child) {
  border-top: 1px solid $sl-border-grey;
}

.dropdown-item {
  position: relative;
  padding: $spacer * 0.65;
  white-space: normal;

  &:not(:first-child) {
    border-top: 1px solid $sl-border-grey;
  }

  &.active,
  &:active {
    &:not(.no-indicator-bar &)::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background-color: $sl-ocean-blue;
    }
  }

  &.disabled {
    pointer-events: all;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  small {
    font-size: $font-size-base;
    font-style: italic;
    font-weight: 500;
  }
}

h3,
.h3 {
  margin-bottom: 0.25rem;
}

.alert-warning {
  background-color: mix(white, $sl-mistyrose, 60%);
  color: $sl-almost-black;
  border-style: none;
}

// Nav tabs

.nav-tabs {
  padding: 0 $spacer;

  .nav-link {
    color: #585858;
    font-weight: 500;
    border-style: none;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      color: #737373;
    }

    &.active {
      background-color: transparent;
      color: $sl-ocean-blue;
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4px;
        background-color: $sl-ocean-blue;
      }
    }

    + .nav-link {
      margin-left: $spacer;
    }
  }

  .nav-item {
    margin-bottom: 0;
  }
}

//
// Bootstrap's Pill Badges

.badge.badge-pill.badge-tag {
  padding: ($spacer * 0.5) $spacer;
  font-size: $font-size-base;
  font-weight: normal;
}

.modal-lg {
  max-width: 700px;
}

.bs-tooltip-bottom {
  .arrow {
    &::before {
      border-bottom-color: $sl-aliceblue;
    }
  }
}