// needed specific z-index
@mixin behaves-like-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.lease-loading-spinner {
  @include behaves-like-overlay;
  background-color: rgba(#fff, 0.8);
}
