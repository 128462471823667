@import '../../styles/bootstrap-variables';

.root {
  position: relative;
  display: inline-block;
}

.root:focus-within {
  width: 100%;
}

.icon {
  position: absolute;
  left: $input-padding-x;
  top: 50%;
  transform: translateY(-50%);
}

.input {
  height: 40px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: calc(17px + #{$input-padding-x * 2}) !important;
  transition: all 0.5s;
}
