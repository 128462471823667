@import '../../styles/colors';
@import '../../styles/bootstrap-variables';

.cardFullHeight {
  height: calc(100vh - 225px);
  margin: 0px -15px;
  padding-right: 15px;
}

.cardTitle {
  font-size: $font-size-nominal-default;
  margin-bottom: $spacer * 1.5;
}

// Activity Card styles

.activityCardItem {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border-bottom: 1px solid $sl-border-grey;
  padding: 1rem 0.5rem;
  justify-content: space-between;
  border-style: none solid;
  border-width: 4px;
  border-color: transparent;
  display: block;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid $sl-border-grey;
  background-color: $sl-white;
  color: $sl-dark-deep-grey;
  margin-bottom: 16px;

  &.activityCardSidebarItem {
    box-shadow: none !important;
    border: none !important;
    padding: 16px !important;
    margin-bottom: 12px;

    & .activityTitle {
      font-size: 16px;

      & .userAvatar {
        font-size: 14px;
        font-weight: 500;
      }

      & .activityActorName {
        font-weight: 600;
      }
    }

    & .activityDescription {
      font-size: 16px;
    }
  }

  .activityDescription {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
  }

  .activityDate {
    margin-left: auto;
  }

  .activityTitle {
    font-size: 15px;
    display: flex;
    padding-bottom: 8px;
  }
}

.activityCardItem__selected {
  background-color: $sl-table-row-onhover;
  border-left-color: $sl-ocean-blue;
  padding-left: 1.625rem;
  cursor: pointer;

  .activityTitle {
    color: $sl-ocean-blue;
  }
}

.suggestionsData {
  padding-top: 5px;
  font-size: 14px;
  font-weight: 200;
  font-style: italic;
  line-height: 16px;
  margin-left: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;

  &__name {
    font-weight: 400;
  }
}

.profileHeader {
  display: flex;
  align-items: center;
  margin-bottom: $spacer * 2;

  h4 {
    margin-bottom: 0;
    margin-left: $spacer;
  }
}

.navigationContainer {
  padding: 0rem 1.875rem $spacer 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  button {
    padding: 0px 8px;
  }
}

//loader
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 6px;
}
.lds-ellipsis div {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: $sl-border-grey;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 32px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(18px, 0);
  }
}

.userAvatar {
  margin-right: $spacer/2;
  display: inline-flex;
  border: 2px solid $sl-ocean-blue;
  border-radius: 50%;
  max-height: 29px;
}

.activityActorName {
  line-height: normal;
  padding: 5px 0;
  font-weight: 500;
}

.propdocsAvatar {
  width: 29px;
  margin: -2px 15px 0px -7px;
}

.viewEmailButton {
  max-width: 150px;
  margin-top: $spacer;
}
