@import '../../../../../../../styles/_colors';

.wrapper {
  width: 101%;
}

.pills-container {
  margin-top: 16px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: #606060;
  margin-bottom: 10px;
}

.description {
  font-size: 12px;
}

.fields-wrapper {
  padding: 0;

  &__signersDropdownContainer {
    display: flex;
  }
}
