@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

$height: 33px;
$width: 62px;
$space: 2px;
$thumbSize: $height - 2 * $space;

// The following is a modified version of react-toggle's own styling

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  user-select: none;
  transition: opacity 0.25s;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  @media only screen and (max-width: $mobile-width) {
    .react-toggle-track {
      height: 24px;
      width: 48px;
    }

    .react-toggle-thumb {
      height: 18px;
      width: 18px;
      top: 3px;
    }

    &--checked {
      .react-toggle-thumb {
        left: 26px !important;
      }
    }
  }
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle-track {
  padding: 0;
  transition: background-color 0.2s;
  background-color: $sl-dirty-pink;
  width: $width;
  height: $height;
  border-radius: $height * 0.5;
  border: 1px solid transparent;

  .react-toggle--focus & {
    border-color: #a2a2a2;
  }

  .react-toggle:hover:not(.react-toggle--disabled.react-toggle--disabled-custom) &,
  .react-toggle--focus:not(.react-toggle--disabled.react-toggle--disabled-custom) & {
    background-color: $sl-dirty-pink;
    border-color: $sl-light-maroon;
  }

  .react-toggle--checked & {
    background-color: $sl-hospital-green;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled.react-toggle--disabled-custom) &,
  .react-toggle--checked.react-toggle--focus:not(.react-toggle--disabled.react-toggle--disabled-custom) & {
    background-color: $sl-hospital-green;
    border-color: $sl-greenish;
  }
}

.react-toggle-track-check {
  display: none;
}

.react-toggle-track-x {
  display: none;
}

.react-toggle-thumb {
  position: absolute;
  border-radius: 50%;
  box-sizing: border-box;
  transition: left 0.2s;
  width: $thumbSize;
  height: $thumbSize;
  left: $space;
  top: $space;
  border: 1px solid transparent;
  background-color: white;

  &,
  .react-toggle--focus &,
  .react-toggle:active:not(.react-toggle--disabled.react-toggle--disabled-custom) & {
    box-shadow: 0 2px 4px $sl-shadow;
  }

  .react-toggle:hover:not(.react-toggle--disabled.react-toggle--disabled-custom) &,
  .react-toggle--focus:not(.react-toggle--disabled.react-toggle--disabled-custom) & {
    border-color: $sl-light-maroon;
  }

  .react-toggle--checked & {
    left: $width - $thumbSize - $space;
    border-color: transparent;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled.react-toggle--disabled-custom) &,
  .react-toggle--checked.react-toggle--focus:not(.react-toggle--disabled.react-toggle--disabled-custom) & {
    border-color: $sl-greenish;
  }
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;

  &:hover {
    border: none;

    .react-toggle-thumb,
    .react-toggle-track {
      border: none;
    }
  }

  &.react-toggle--disabled-custom {
    opacity: 1;

    .react-toggle-track {
      background-color: transparent;
    }

    .react-toggle-thumb {
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;
      left: 50%;
      transform: translateX(-50%);
      background-image: url('../../shared/icons/cross-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &.react-toggle--checked .react-toggle-thumb {
      background-image: url('../../shared/icons/checkmark-icon.svg');
    }
  }
}

.react-toggle.react-toggle-blue,
.react-toggle.react-toggle-blue:hover,
.react-toggle.react-toggle-blue--focus {
  .react-toggle-track {
    background-color: $sl-disabled-button !important;
    border: none;
  }

  .react-toggle-thumb {
    background-color: $sl-ocean-blue;
    border: none;
  }
}
