@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

.root {
  display: flex;
  min-height: 100vh;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.left {
  flex-basis: 40%;
  background: linear-gradient(235deg, white, rgba(white, 0.9) 35%, rgba(white, 0.8) 50%, rgba(white, 0.2)),
    url('../../shared/icons/cube-texture-blue.svg') center/168px, white;
  text-align: -webkit-center;

  @media only screen and (max-width: 700px) {
    display: none;
  }
}

.right {
  flex: auto;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 700px) {
    align-items: center;
    justify-content: center;
  }
}
.nav {
  align-self: flex-end;
  margin-bottom: $spacer * 2;
  padding-bottom: 30px;

  & > * + * {
    margin-left: $spacer * 0.5;
  }

  @media only screen and (max-width: 700px) {
    margin: 0;
    padding: 0;
  }
}

.main {
  margin: 0 auto;
  width: 100%;
  max-width: 28em;

  // This overrides a style setting in the private-layout styles file
  &:global(#main-content) {
    margin-left: auto;
  }
}

.mobileHeader {
  display: none;

  @media only screen and (max-width: 700px) {
    display: block;
  }
}
