@import '../../styles/bootstrap-variables';
@import '../../styles/functions';

// These are minor tweaks of the Bootstrap defaults for now, all other customization is done in bootstrap-variables
.root.root {
  // ☝️ Extra specificity required to override Bootstrap
  > input:focus ~ label::before {
    border-color: $custom-control-indicator-focus-border-color;
  }

  &.checkbox > input:checked ~ label::before {
    background-color: $green;
  }

  &.radio > input {
    &:checked ~ label::after {
      background-size: 90% 90%;
    }
    &:disabled:checked ~ label {
      &::before {
        background-color: white;
      }
    }
  }
}
