.CalendarDay__default {
  &:hover {
    background: mix(white, $sl-aliceblue, 55%);
    border: none;
  }
}

.CalendarDay__selected_span {
  background: mix(white, $sl-aliceblue, 55%);
  color: $sl-almost-black;
  &:hover {
    background: $sl-light-blue;
    border: none;
    color: $sl-almost-black;
  }
}

.CalendarDay {
  border: none;
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  position: relative;
  &.CalendarDay {
    &,
    &:active,
    &:hover {
      background: $sl-ocean-blue;
      border-color: $sl-ocean-blue;
    }
  }

  &:hover {
    border: none;
  }
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: mix(white, $sl-aliceblue, 55%);
}

.DayPickerNavigation_button__horizontalDefault {
  top: $spacer * 1.3125;
  padding: ($spacer * 0.4375) ($spacer * 0.5625);
  color: #a2a2a2;
  border: none !important;

  &:hover {
    color: #737373;
    border-color: #737373;
  }
}
