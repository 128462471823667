@import '../styles/bootstrap-variables';
@import '../styles/colors';

.spacedRows {
  :global(.row) + :global(.row) {
    margin-top: $spacer;
  }
}

.teamTitle {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropDownSearchBox {
  padding: 0.73rem;
}

.contentTable {
  height: calc(100% - 40px - $spacer);
  overflow: auto;

  @media only screen and (max-width: $mobile-width) {
    height: calc(100% - 128px);
  }
}