@import '../../../../../../styles/colors';
@import '../../../../../../styles/bootstrap-variables';

.wrapper {
  .title {
    text-align: left !important;
  }

  .emphasize {
    font-weight: 800;
  }
}

.cancel-button {
  transition: opacity 0.15s ease;

  &:disabled {
    opacity: 0.5 !important;
    background-color: $sl-blood-orange !important;
  }

  &:disabled:hover {
    background-color: $sl-blood-orange !important;
  }
}

.uploadModalWrapper {
  background-color: pink;
  max-width: 100px;
}

.uploadModalLabel {
  color: #737373;
  font-weight: 700;
}

.signersBadgeContainer {
  display: inline-block;
  background-color: $sl-teal;
  border-radius: 2em;
  margin: 0 -70px 0 $spacer;
  padding: 0 $grid-gutter-width * 0.5;
  height: calc(1.5em + 4px);
  font-size: 12.8px;
  font-weight: 400;
  line-height: 1.7;
  align-self: center;

  &__cancelButton,
  &__signersCounter {
    color: $sl-ocean-blue;
    text-decoration: none;
    background-color: transparent;
  }

  &__cancelButton {
    &:hover {
      cursor: pointer;
      color: $sl-deep-sea-blue;
      text-decoration: underline;
    }
  }

  &__signersCounter {
    &:hover {
      cursor: default;
      color: $sl-deep-sea-blue;
    }
  }

  &__counter {
    padding-left: $spacer/2;
    color: $sl-ocean-blue;
  }
}

.signersContainer {
  display: flex;
}

.signerData {
  text-align: left;
  white-space: nowrap;
}

.signerStatus,
.signerStatus__signed {
  text-transform: capitalize;
  font-weight: bold;
  text-align: end;
}

.signerStatus__signed {
  color: $sl-greenish;
}

.restrictedEditingField {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px 0;

  &__label {
    color: #737373;
    font-weight: 700;
    margin-right: $spacer;
    padding-top: $spacer/3;
  }

  &__tooltip {
    display: inline-block;
    transform: translateY(-10px);
  }
}

.placeholderCompletionModal {
  &__title {
    margin: 2rem 0;
    font-size: 1rem;
  }
  &__buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
}
