@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

.resultsList {
  border-top: 1px solid $sl-aliceblue;
  max-height: 320px;
  overflow: auto;
}

.selectDealDropDownContainer {
  @media only screen and (max-width: $mobile-width) {
    .selectDealDropDown {
      display: none;
    }
  }
}

.selectDealDropDown {
  padding: 0.75rem;
}
.dealDropdown {
  @media only screen and (max-width: $mobile-width) {
    width: 100%;
    border: none !important;
    border-radius: 16px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-radius: 16px 16px 0px 0px;
    background-color: $sl-white;
    position: relative;
    ul {
      padding: 16px 16px 34px 16px;
      li {
        border: none !important;
        padding-bottom: 12px;
        &:last-child{
          padding-bottom: 0;
        }
        :global(.form-group) {
          label {
            padding: 0;
            span{
              font-size: 16px;
              color: $sl-dark-grey-mobile;
            }
            &::after {
              left: auto;
              right: 0;
              
            }
            &::before {
              @extend ::after;
       
            }
          }
        }
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 56px;
      width: 100%;
      height: 4px;
      background-color: $sl-line-gray;
      border-radius: 100px;
    }
    &::before {
      top: auto;
      bottom: 9px;
      max-width: 135px;
      height: 5px;
      background-color: $sl-dark-grey-mobile;
      @extend ::after
    }
  }
}

.selectDealsDropdown {
  padding: 24px 16px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0;
    font-size: 24px;
    color: $sl-dark-grey-mobile;
  }
}

.crossIcon {
  width: 16px;
  height: 16px;
  
  path {
    stroke: $sl-dark-grey-mobile;
  }
}
