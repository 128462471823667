@import '../../../styles/colors';
@import '../../../styles/bootstrap-variables';

.subscribtionRequiredModal {
  align-items: center;

  div {
    max-width: 500px;
  }
}

.subscribtionRequiredModalBody {
  color: $sl-dark-grey-blue;

  p {
    display: flex;
    margin-bottom: $spacer/2;

    * {
      margin-right: $spacer/2;
    }

    &:first-of-type {
      margin-bottom: $spacer;
    }

    &:last-of-type {
      margin-left: $spacer * 2;
      min-height: 32px;
    }
  }
}

.subscribtionRequiredModalContainer {
  margin: 20px 20px;
}

.subscribtionRequiredModalHeader {
  font-weight: 500;
  padding-bottom: 10px;
  text-align: left;
  color: $sl-dark-grey-blue;
}

.upgradeButtonContainer {
  text-align: center;
  margin-top: $spacer;
}

.subscribtionRequiredModalFooter {
  font-size: small;
  text-align: center;
  color: $sl-dark-grey-blue;
}
