@import '../../lease-wizard/lease-wizard-shared';
@import '../../../styles/colors';

$lease-editor-border-colour: RGB(0 0 0 / 5%);

@mixin button-handlers {
  margin: 3px 7px;
  padding: 0 8px;
  font-size: 10pt;
  width: 90px;
  min-height: 20px;
  height: 25px;
  justify-content: center;
  border-radius: 4px;

  &:active {
    border: none;
  }
}

* {
  --ck-inline-annotation-container-width: 300px;
  --ck-inline-annotation-container-max-height: 400px;
}

.lease-editor {
  border: 1px solid $lease-editor-border-colour;
  background-color: $sl-background-grey;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .ck.ck-sidebar--narrow {
    .ck-sidebar-item {
      .ck-annotation-counter__badge {
        z-index: auto;
      }
      .ck-icon.ck-button__icon {
        position: relative;
        left: -4px;
        top: 4px;
      }
    }
  }
}

// This style is only for the formatting toolbar drodpwn in the Template Editor ONLY
.ck-toolbar__nested-toolbar-dropdown .ck-reset.ck-dropdown__panel.ck-dropdown__panel_se.ck-dropdown__panel-visible {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

// This style is for the comment dropdown menu that appears in the Document Editor ONLY
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
  position: absolute;
  top: 100%;
}

// This style is only for the formatting toolbar drodpwn in the Document Editor ONLY
.ck-toolbar__nested-toolbar-dropdown .ck-reset.ck-dropdown__panel.ck-dropdown__panel_sw.ck-dropdown__panel-visible {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.comment_indicator:not(.comment_indicator--isActive):not(.comment_indicator--isDirty) {
  display: none !important;
}

.lease-editor__with-slide-out {
  margin-left: 0px;
}

.ck.ck-button.ck-off.ck-comment--resolve {
  display: none !important;
}

.lease-editor__toolbar {
  z-index: 1;
  box-shadow: 3px 0 3px $lease-editor-border-colour;
  border-bottom: 1px solid $lease-editor-border-colour;
  width: 100%;
}

.lease-editor__toolbar .ck.ck-toolbar {
  border: 0;
  border-radius: 0;
  .ck-toolbar__items {
    justify-content: center;
  }
}

.lease-editor__editable-container {
  position: relative;
  background-color: $sl-background-grey;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  padding: calc(2 * var(--ck-spacing-large));
  height: 100%;
  width: 100%;
  margin-bottom: 25px;
  z-index: 0;

  .ck-content.ck-editor__editable,
  .lease-editor__sidebar {
    align-items: stretch;
    flex-direction: column;
    border: none;
    height: 100%;
  }
  .lease-editor__sidebar.narrow {
    min-width: 65px;
    width: 55px;
  }
}

#Dynamic {
  max-width: 340px;
}

#Editor .ck.ck-editor__editable,
#Editor .ck.ck-editor__editable_inline {
  box-sizing: border-box;
  max-width: calc(8.5in + 2px);
  min-width: calc(8.5in + 2px);
  min-height: 11in;
  height: auto;
  padding: 1in;
  margin: 0 5px 10px 0;
  border: 1px solid $lease-editor-border-colour;
  box-shadow: $lease-document-box-shadow;
  background-color: white;

  figcaption {
    width: auto;
    background: none;
    border: 0 none;
    box-shadow: none;
    min-height: auto;
  }

  span.form-connector-value.ck-widget {
    background-color: $sl-teal;
    &:hover {
      outline-color: $sl-hospital-green;
    }
  }
}

body .ck-content .table table, body .ck-content .table td {
  border: dotted 1px rgba(54, 54, 54, 0.2);
}

body .ck-content .table table td, .ck-content .table table th {
  border: dotted 1px rgba(54, 54, 54, 0.2);
}

body .ck-content .table table td {
  vertical-align: top;
}

.lease-editor__sidebar {
  min-width: 35px;
  padding: 0 10px;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: 1px solid $lease-editor-border-colour;
  box-shadow: $lease-document-box-shadow;
  outline: 1px solid black;
}

.lease-editor {
  .ck.ck-sidebar--narrow {
    .ck-sidebar-item {
      justify-content: left;

      .ck-annotation-counter__badge {
        z-index: auto;
      }
      .ck-icon.ck-button__icon {
        position: relative;
        left: -4px;
        top: 4px;
      }
    }
  }
}

.ck.ck-mentions {
  .ck-list__item {
    .custom-item.ck-button {
      width: 100%;
      display: block;
      cursor: pointer;
      color: $sl-dark-grey-blue;
      &:hover {
        background: #f7f3f9;
      }
      .custom-item-username,
      > span {
        color: $sl-dark-grey-blue;
        &:hover {
          cursor: pointer;
        }
      }
      &.ck-on,
      &.ck-on:hover,
      &.ck-on:hover:not(.ck-disabled) {
        background: $sl-ocean-blue;
        color: $sl-whitesmoke;
        .custom-item-username {
          color: $sl-whitesmoke;
        }
      }
    }
  }
}

.ck-body-wrapper {
  .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
  }
}

#Sidebar .ck .ck-comment__input .ck-editor__editable_inline {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 5px;
  border: 1px solid $lease-editor-border-colour;
  box-shadow: $lease-document-box-shadow;
  background-color: white;
}

.ck-sidebar {
  width: 280px !important;

  &:empty {
    width: 0px !important;
  }
}

.ck .ck-sidebar-item {
  max-width: 380px;
  z-index: 0;
  padding-bottom: 20px;
}

.ck .ck-annotation__info-name {
  max-width: 100px;
}

.tippy-tooltip {
  background-color: $sl-aliceblue !important;
  border-radius: 4px !important;
  color: black !important;
}

.tippy-popper[x-placement^='top'] [x-arrow].arrow-small {
  border-top: 5px solid $sl-aliceblue !important;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  bottom: -5px;
}

.ck.ck-button.ck-button_with-text .ck-button__icon {
  height: 20px;
  width: 24px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.editors-holder {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.editor-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
}

.ck.ck-editor {
  max-width: 800px;
}

#revision-viewer-container {
  display: none;
}

.editor-container > .ck-editor {
  position: relative;
  width: 950px;
}

.editor-container .ck-editor__top .ck-toolbar {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.editor-container .ck-editor__editable_inline {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sidebar-container {
  position: relative;
  border: 1px solid var(--ck-color-toolbar-border);
  margin-left: -1px;
}

.ck.ck-button.ck-off.ck-button_with-text.ck-comment__input-actions--cancel {
  @include button-handlers;
  border: solid 1px $sl-ocean-blue;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(137, 159, 207, 0.5);
  color: $sl-ocean-blue;

  &:hover {
    background-color: $sl-table-stripe;
  }
}

.ck.ck-button.ck-off.ck-disabled.ck-button_with-text.ck-comment__input-actions--submit {
  color: $sl-almost-black;
  background-color: transparent;
  box-shadow: none;
}

.ck.ck-button.ck-off.ck-button_with-text.ck-comment__input-actions--submit {
  @include button-handlers;
  border-radius: 4px;
  background-color: $sl-ocean-blue;
  color: $sl-background-grey;
  box-shadow: 0px 1px 1px rgba(25, 38, 66, 0.5);

  &:hover {
    background-color: $sl-blue-button-onhover;
  }
}

.ck .ck-comment__input-actions.ck-comment__input-actions--active {
  margin-top: 8px;
}

.ck-thread__remove-confirm {
  background-color: $sl-disabled-text !important;
}

button.ck.ck-button.ck-off.ck-thread__remove-confirm-submit {
  @include button-handlers;
  color: $sl-background-grey;
  background-color: $ck-button-cancel;

  &::before {
    content: 'Delete';
  }

  .ck-icon.ck-button__icon {
    display: none;
  }

  &:hover {
    background-color: $ck-button-cancel-onhover;
    cursor: pointer;
  }
}

button.ck.ck-button.ck-off.ck-thread__remove-confirm-cancel {
  @include button-handlers;
  color: $sl-ocean-blue;
  background-color: $sl-background-grey;

  &::before {
    content: 'Cancel';
  }

  .ck-icon.ck-button__icon {
    display: none;
  }

  &:hover {
    background-color: $sl-card-background-blue;
    cursor: pointer;
  }
}

.revision-viewer {
  $root: &;

  &__container {
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: #ffffff;

    &_counterparty {
      padding-left: 0;
    }

    &_no-editing-name {
      .ck.ck-labeled-field-view > .ck.ck-labeled-field-view__input-wrapper {
        pointer-events: none;
      }

      .ck.ck-revision-history-sidebar__revision-wrapper
        .ck-revision-history-sidebar__revision.ck-revision-history-sidebar__revision_selected {
        .ck-revision-history-sidebar__revision__actions {
          display: none;
        }
      }
    }

    .editor-container {
      height: 100%;
    }

    #{$root}__sidebar {
      overflow-y: scroll;
      padding-bottom: 60px;
    }

    #{$root}__editor {
      max-width: calc(8.5in + 2px);
      min-width: calc(8.5in + 2px);
      margin-top: 40px;
    }

    #{$root}__editor.ck.ck-editor__editable_inline {
      padding-left: 20px;
    }
  }

  &__toolbar {
    z-index: 1;
    position: absolute;
    border-bottom: 1px solid $lease-editor-border-colour;
    width: 100%;

    .ck.ck-button.ck-revision-history-ui__back-to-editing {
      display: none;
      background-color: $sl-blood-orange;

      .ck-button__label {
        color: white;
      }
    }

    .ck.ck-button.ck-revision-history-ui__back-to-editing:hover:not(.ck-disabled) {
      display: none;
      background-color: $sl-coral;
    }

    .ck.ck-button.ck-revision-history-ui__back-to-editing:hover:not(.ck-disabled):active {
      display: none;
      border: 1px solid $sl-coral;
      box-shadow: none;
    }

    .ck.ck-button .ck-button__label {
      color: $sl-blood-orange;
    }
  }

  &__sidebar {
    height: 100%;
    margin-top: 38px;
    flex-grow: 1;

    .ck-revision-history-sidebar__revision-wrapper {
      max-width: 400px;
    }

    .ck.ck-revision-history-sidebar {
      height: 100%;
    }

    .ck.ck-revision-history-sidebar__revision-wrapper
      .ck-revision-history-sidebar__revision.ck-revision-history-sidebar__revision_selected {
      background: $sl-dark-grey-blue;
    }

    .ck.ck-revision-history-sidebar__revision-wrapper
      .ck-revision-history-sidebar__revision
      .ck-revision-history-sidebar__revision__date {
      color: $sl-disabled-text;
    }

    .ck.ck-revision-history-sidebar__time-period .ck-revision-history-sidebar__time-period__label {
      color: $sl-disabled-text;
      background: #ffffff;
    }

    .ck.ck-revision-history-sidebar__header {
      svg {
        display: none;
      }
    }
  }
}

.spinner__document-preparing {
  z-index: 1;
}

.ck.ck-tooltip.suggestion-tooltip .ck-tooltip__text {
  //color: #000;
  display: block;
  max-width: 150px !important;
  font-size: 0px !important;
}

.ck.ck-tooltip.suggestion-tooltip {
  width: 150px;
  height: 75px;
  color: #000;
  background-color: #ddebef;
  overflow-wrap: anywhere;
  white-space: wrap !important;
}
.ck.ck-tooltip.suggestion-tooltip .ck-tooltip__text:after {
  font-size: 12px;
  content: 'Your counterparty must \00000aaccept or discard \00000asuggestions made by \00000ayour team.';
  white-space: pre;
  color: #000;
}

.ck-content .table thead {
  display: table-row-group;
}

.ck-content .table {
  margin: 0.9em auto;
  display: table;
}

// #Editor figure.table {
//   width: unset;
// }
.ck.ck-editor__editable.ck-pagination-view .ck-table-column-resizer {
  display: none;
}

ol {
  padding-left: 15px;
}

ol[style='list-style-type:decimal-leading-zero;'] {
  list-style-type: none !important;
  counter-reset: item !important;
  padding-left: 0px !important;

  span.ck-list-bogus-paragraph {
    display: inline !important;
  }
  p {
    display: inline !important;
  }
}

ol[style='list-style-type:decimal-leading-zero;'] > li::before {
  content: counter(item) '. ' !important;
  counter-increment: item !important;
  margin-right: 5px !important;
}

ol[style='list-style-type:decimal-leading-zero;'] > li ol {
  list-style-type: none !important;
  padding-left: 10px !important;
  counter-reset: subitem !important;
}
ol[style='list-style-type:decimal-leading-zero;'] > li ol > li::before {
  content: counter(item) '.' counters(subitem, '.') ' ' !important;
  counter-increment: subitem !important;
  margin-right: 5px !important;
}

.ck.ck-dropdown .ck-button.ck-dropdown__button .ck-button__label {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
