@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

.root {
  color: $sl-ocean-blue;
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: flex-end;

  & > :not(:first-child) {
    margin-left: 0.5 * $spacer;
  }
}
