@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

.root {
  display: flex;
  justify-content: center;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.icon {
  align-self: center;
}

.overlay {
  position: fixed !important;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: transparentize($modal-backdrop-bg, $modal-backdrop-opacity);
  z-index: $zindex-tooltip;
}
