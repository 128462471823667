@import '../../../../styles/colors';

.wrapper {
  .title {
    text-align-last: left;
  }

  .main {
    text-align-last: left;
  }
}

.cancel-button {
  transition: opacity 0.15s ease;

  &:disabled {
    opacity: 0.5 !important;
    background-color: $sl-blood-orange !important;
  }

  &:disabled:hover {
    background-color: $sl-blood-orange !important;
  }
}
