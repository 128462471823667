@import 'src/styles/_colors.scss';

.wrapper {
  display: flex;
  background-color: $sl-white;
  box-shadow: 2px 2px 4px $sl-medium-grey;
  position: relative;
  transition: opacity 0.15s ease;
  overflow: hidden;
  margin: 10px 2px;

  &-dragging {
    opacity: 0.5;
  }
}

.button {
  min-width: unset;
  min-height: unset;
  background: transparent;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 1px;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  width: 26px;
  height: 26px;
  transition: background-color 0.15s ease;
  border-radius: 2px;

  &:disabled {
    background-color: $sl-light-grey;
    cursor: unset;
  }

  &:hover {
    background-color: $sl-light-grey;
  }
}

.content {
  padding: 3px;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  transition: transform 0.2s linear;

  &-deleting {
    transform: translate3d(-102%, 0, 0);
    overflow: hidden;
  }
}

.delete {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: var(--ck-color-comment-remove-background);
  animation: fadeIn;
  animation-duration: 0.2s;

  &-title {
    margin-bottom: 5px;
    font-size: 20px;
  }

  &-buttons {
    margin: 0;
  }
}

@keyframes fadeIn {
  from {
    left: 100%;
  }
  to {
    left: 0;
  }
}

.firstRow {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &:hover {
    cursor: move;
  }
}

.secondRow {
  padding: 5px;
  height: 100px;
  overflow: auto;
  display: none;

  &.show-template {
    display: block;
  }
}

.order {
  display: inline-flex;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: 1px solid;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 2px;
}

.icon {
  &-edit {
    color: $sl-dark-grey-blue;
  }

  &-delete {
    color: $ck-button-cancel;
  }

  &-drag {
    width: 24px;
    color: $sl-medium-grey;
  }

  &-expand-arrow {
    width: 24px;
    padding-right: 10px;
    color: $sl-ocean-blue;
    transform: scale(1.5);

    &-up {
      padding: 0 0 0 10px;
      transform: scale(1.5) rotate(0.5turn);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-approve {
    color: $sl-greenish;
  }

  &-reject-delete {
    color: $ck-button-cancel;
  }

  &-edit,
  &-delete,
  &-approve,
  &-reject-delete {
    opacity: 0.8;
  }

  &-edit:hover,
  &-delete:hover,
  &-approve:hover,
  &-reject-delete:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.template-title {
  padding: 6px 0px 4px 8px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.drag-handle {
  margin-right: 5px;

  &:hover {
    cursor: move;
  }
}

.expand-handle {
  display: inline-block;
  padding: 0px 5px 5px 15px;
  align-self: end;

  &:hover {
    cursor: pointer;
  }
}
