@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

.logoFull {
  display: flex;
  padding-top: 69px;
  width: 285px;

  @media only screen and (max-width: $mobile-width) {
    padding-top: 0px;
    margin: 0px;
    width: 240px;
  }
}

.logoIcon {
  align-items: center;
  color: $sl-ocean-blue;
}
