@import '../../styles/bootstrap-variables';

.roleButton {
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px $sl-aliceblue;
    border-radius: $border-radius;
  }
}

.roleCard {
  transition-property: border-color background-color;
  transition-duration: 200ms;
  color: #737373;

  &:hover {
    color: $sl-ocean-blue;
  }

  &:after {
    content: ' ';
    background-image: url('../../shared/icons/register-selected-icon.svg');
    background-size: 100%;
    position: absolute;
    top: -5px;
    right: -5px;
    width: 22px;
    height: 22px;
    transform: scale(0);
    opacity: 0;
    transition-property: transform opacity;
    transition-duration: 200ms;
  }

  &:hover {
    border-color: $sl-ocean-blue;
  }
}

:global(.active) {
  .roleCard {
    background-color: $sl-aliceblue;
    border-color: $sl-ocean-blue;
    color: $sl-ocean-blue;

    &:after {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.roleCardIcon {
  width: 52px;
  height: 52px;
}

.register-form-wrapper {
  max-width: 30em;
}

.magicLinkContainer__dealsPage {
  width: 100dvw;
  height: 100dvh;
  background-color: lightblue;
  background-image: url('../../../public/images/BlurredDealsPage.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.magicLinkContainer__blankDocPage {
  width: 100dvw;
  height: 100dvh;
  background-color: lightblue;
  background-image: url('../../../public/images/BlurredBlankDoc.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.magicLinkContainer__dealDetailsPage {
  width: 100dvw;
  height: 100dvh;
  background-color: lightblue;
  background-image: url('../../../public/images/BlurredEmptyDealDetails.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.magicLinkHeader {
  margin: -1em 0 0.3em;
  text-align: center;
}

.magicLinkSubHeader {
  text-align: center;
}

.expiredTokenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  width: 100dvw;
  text-align: center;

  h1 {
    margin-top: 2em;
    margin-bottom: 0;
  }

  h2 {
    margin-top: 1em;
    font-weight: 500;
    font-size: 1.4em;
  }

  button {
    margin-top: 1.5em;
  }
}

.expiredContactText {
  margin-top: 6em;
}
