@import 'src/styles/colors';

.primaryTooltip > :global(.tooltip-inner) {
  background-color: $sl-aliceblue;
  color: $sl-almost-black;
}

.primaryTooltip > :global(.arrow):before {
  border-top-color: $sl-aliceblue !important;
  color: $sl-almost-black;
}
