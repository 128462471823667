@import '../../styles/bootstrap-variables';
@import '../../styles/colors';
@import '../../styles/form';

$lease-wizard-spacer: $spacer * 1.5;
$lease-rte-indent: 2rem;
$lease-rte-bullets: (
  0: none,
  1: lower-alpha,
  2: lower-roman,
  3: lower-greek,
  4: decimal,
  5: upper-alpha,
  6: upper-roman,
  7: upper-greek,
  8: georgian,
  9: armenian,
  10: hebrew,
);
@mixin loop-list-items($level) {
  @if $level <= 10 {
    li {
      &:before {
        content: '(' counter(rte, map-get($lease-rte-bullets, $level)) ') ';
      }
      @include loop-list-items($level + 1);
    }
  }
}

@mixin lease-wizard-rte-styles {
  font-size: $font-size-base;
  line-height: $line-height-base;

  ol {
    counter-reset: rte;
    list-style: none;
    padding: 0 0 0 $lease-rte-indent;
    margin: 1rem 0;
    li {
      position: relative;
      margin: 0.5rem 0;
      counter-increment: rte;
      &:before {
        position: absolute;
        transform: translateX(-$lease-rte-indent);
        content: '(' counter(rte, decimal) ') ';
      }
    }
    // skip none in the $list-bullet-styles
    @include loop-list-items(1);
  }

  table {
    width: 100%;
    border-color: $sl-whitesmoke;
    border: none;
    margin-bottom: $spacer;
    th {
      font-weight: 700;
    }
    th,
    td {
      padding: $spacer * 0.5 $spacer;
    }

    thead {
      th,
      td {
        border: none;
        background: transparent;
      }
    }

    tbody {
      border: 2px solid $sl-whitesmoke;
      tr:nth-child(odd) {
        background: $sl-whitesmoke;
      }

      th,
      td {
        border: 1px solid $sl-whitesmoke;
        border-width: 1px 0 1px 0;
      }
    }
  }
}

$lease-document-paper-padding: 5rem;
$lease-document-box-shadow: 0 2px 5px rgba($sl-almost-black, 0.3);
@mixin lease-document-paper-style {
  position: relative;
  margin: 0;
  margin-bottom: $lease-wizard-spacer;
  padding: $lease-document-paper-padding;
  background: #fff;
  box-shadow: $lease-document-box-shadow;
  border-radius: $border-radius-sm;
}

@mixin lease-document-title-style {
  padding-bottom: 0.5em;
  margin-bottom: 2rem;
  border-bottom: 2px solid $sl-almost-black;
  font-size: 1.5rem;
  font-weight: 500;
}

// Lease Document Wizard page styles
$wizard-header-height: 49px;
$wizard-header-shadow-size: 3px;

$wizard-sidebar-width: 300px;
$wizard-sidebar-shadow-size: 3px;

$wizard-page-size: 900px;
$wizard-page-horizontal-margin: 90px;
$wizard-page-vertical-margin: 32px;
