@import '../../../styles/bootstrap-variables';
@import '../../../styles/colors';

@mixin truncateContent($width) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teamSidebarTopToggleContainer {
  padding: 0.85rem 1.25rem !important;
}

.teamSidebarTopToggle {
  width: 100%;
  label {
    border-radius: unset !important;
  }
}

.teamSidebarSectionButton {
  background-color: transparent !important;
  border-color: transparent !important;
  color: $sl-ocean-blue !important;
  width: 50%;
  border: none !important;
  border-bottom: solid 2px $sl-ocean-blue !important;
  padding-bottom: 11px !important;
  border-radius: 0 !important;

  input {
    display: none;
  }
}

.teamSidebarSectionButtonActive {
  background-color: transparent !important;
  border-color: transparent !important;
  color: $sl-ocean-blue !important;
  width: 50%;
  border: none !important;
  border-bottom: solid 5px $sl-ocean-blue !important;
  border-radius: 0 !important;

  input {
    margin-bottom: 3px;
    display: none;
  }
}

// Deal Team Styles

.teamListContainer {
  height: calc(100vh - 200px);

  table {
    thead {
      display: none;
    }
  }
}

.teamListItems {
  height: calc(100vh - 320px);
  overflow-y: auto;
  overflow-x: visible;
  scrollbar-width: thin;
}

.teamListItemsNonInvite {
  height: calc(100vh - 220px);
  overflow-y: auto;
  overflow-x: visible;
  scrollbar-width: thin;
}

.cancelButton {
  background-color: $sl-white !important;
  color: $ck-button-cancel !important;
  &:hover {
    background-color: $ck-button-cancel !important;
    color: $sl-white !important;
  }
}

.teamMemberContainer {
  border-bottom: 1px solid $sl-border-grey;
  overflow-x: hidden;
  min-width: 170px;
  &:last-child { border-bottom: none; }
}

.deleteButton {
  button {
    padding: 4px;
  }
}

.teamMemberName {
  font-size: $font-size-base-target;
  margin-bottom: 0;
  @include truncateContent(160px);
}

.teamMemberEmail {
  @include truncateContent(185px);
}

.permissionsContainer {
  padding-top: 0.5em;
  ul {
    justify-content: flex-start;
  }
}

.dealNameInfoAndDelete {
  display: flex;
  justify-content: space-between;
  max-width: 120px;
}

.inviteContainer {
  position: sticky;
  padding-bottom: 1rem;
}

// END: Deal Team Styles

.counterpartyName {
  display: flex;
  h4:first-of-type {
    @include truncateContent(160px);
    margin-right: 0.5em;
  }
}

.nameAndDeleteContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teamMembersTable {
  width: 95%;
  tbody {
    tr:not(.expansion) {
      td {
        border-right: transparent solid 1px !important;
        border-top: transparent solid 1px !important;
        border-left: transparent solid 1px !important;
        padding-top: 15px !important;
        padding-bottom: 15px !important;

        &:first-of-type {
          padding-left: 0 !important;
        }
        &:first-child {
          padding-left: 15px !important;
        }
        &:last-child {
          padding-right: 15px !important;
        }
      }
    }
  }
}

.permissionsSubRow {
  display: flex !important;
  div {
    display: flex !important;

    div:first-of-type {
      display: flex;
    }
  }

  div:first-of-type {
    span {
      svg {
        color: #006c90;
      }
    }
    div:first-of-type {
      span:nth-of-type(2) {
        margin-left: 0.4em !important;
      }
    }
  }
}

.customToggleStyles {
  transform: scale(0.6);
}

.warningBackground {
  background-color: #fff3ef;
  font-size: 0.85em;
  font-weight: bold;
  button {
    font-size: 1em;
    font-weight: bold;
  }
}
