@import '../../styles/colors';

.passwordField {
  button {
    right: 0;
    bottom: 100%;
    position: absolute;
    color: $sl-dark-grey;
    font-weight: 600;
    font-size: 12px;
  }
}

.customSelect {
  > option[value=''][disabled] {
    display: none;
  }
}
