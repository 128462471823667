@import '../styles/bootstrap-variables';

.formContainer {
  @media screen and (max-width: $mobile-width) {
    input, select, textarea {
      font-size: 16px;
    }
  }
}

.resendVerificationLink {
  color: #006c90;
  text-decoration: none;
  background-color: transparent;
  border: none;

  &:hover {
    color: #003344;
    text-decoration: underline;
  }
}

.inviteUsersTableHeader {
  th {
    text-align: center;
    padding: 0.5rem 0 !important;
    min-width: 70px !important;
  }
}

.inviteUsersTableRow {

  td:first-of-type {
    text-align: left;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  td {
    text-align: center;
    padding: 0.5rem;
  }
}

.inviteToggle {
  width: 25px !important;
  height: 12px !important;
}

.dealInviteModal {
  margin-top: $spacer;

  &__input {
    display: block;
    width: 86%;
    min-height: 90px;
    padding: 0.375rem 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    border: 1px solid #a2a2a2;
    border-radius: 0.125rem;
    resize: none;
    align-self: center;
    position: relative;
    left: 50%;
    transform: translatex(-50%);
    margin-bottom: 1em;

    &:focus {
      border-color: #006c90;
      outline: 0;
      box-shadow: none;

      &::placeholder {
        visibility: hidden;
      }
    }
  }
}

.customToggleStyles {
  $height: 20px;
  $width: 40px;
  $space: 2px;
  $thumbSize: $height - 2 * $space;
  > div:first-of-type {
    height: $height !important;
    width: $width !important;
  }

  > div:nth-of-type(2) {
    width: $thumbSize;
    height: $thumbSize;
    .react-toggle--checked & {
      left: $width - $thumbSize - $space;
      border-color: transparent;
    }
  }

  &.react-toggle--checked > div:nth-of-type(2) {
    width: 30px;
  }
}

.dividerContainer {
  border: 1px solid #a2a2a2;
  border-bottom: none;
  position: relative;
  display: flex;
  margin-top: 32px;
  justify-content: center;

  .divider {
    position: absolute;
    border: 1px solid #a2a2a2;
    padding: 0px 4px;
    display: inline-block;
    top: -12px;
    background-color: white;
  }
}

.signupModal__body {
  overflow-y: scroll;
  max-height: calc(60dvh - 2em);
  margin: 2em 0;
}

.signupModal__actions {
  display: flex;
  justify-content: right;
  gap: 2em;
}

.inviteContinueButton {
  width: 150px;
}

.counterpartyInviteNames {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-top: 0.5em;
  width: 100%;

  > div  {
    flex: 1;
  }
}
