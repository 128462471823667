@import '../../../../../../styles/bootstrap-variables';
@import '../../../../../../styles/colors';

.internalApprovalBadgeContainer {
  display: inline-block;
  background: $sl-aliceblue;
  border-radius: 2em;
  margin: 0 -70px 0 $spacer;
  padding: 0 $grid-gutter-width * 0.5;
  height: calc(1.5em + 4px);
  font-size: 12.8px;
  font-weight: 400;
  line-height: 1.7;
  align-self: center;

  &__cancelButton,
  &__approversCounter {
    color: $sl-ocean-blue;
    text-decoration: none;
    background-color: transparent;
  }

  &__cancelButton {
    &:hover {
      cursor: pointer;
      color: $sl-deep-sea-blue;
      text-decoration: underline;
    }
  }

  &__approversCounter {
    &:hover {
      cursor: default;
      color: $sl-deep-sea-blue;
    }
  }
}

.approversContainer {
  display: flex;
}

.approverData {
  text-align: left;
  white-space: nowrap;
}

.reviewStatus,
.reviewStatus__approved {
  text-transform: capitalize;
  font-weight: bold;
  text-align: end;
}

.reviewStatus__approved {
  color: $sl-greenish;
}

.internalApprovalTooltipTitle {
  font-weight: 500;
  text-align: left;
  margin: 0 15px;
}
