@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

.dealDetails {
  dl {
    display: flex;
    align-items: baseline;

    > div + div {
      margin-left: $spacer;
    }

    dt {
      color: #737373;
    }
  }

  ul {
    margin-top: $spacer;
    list-style-type: none;
    column-count: 2;
    column-gap: $spacer;
    padding: 0;
  }
}

.contentTable {
  height: calc(100% - 40px - $spacer);
  overflow: auto;

  @media only screen and (max-width: $mobile-width) {
    height: calc(100% - 128px);
  }
}

.dealPageHeader {
  :global(.row) + :global(.row) {
    margin-top: $spacer;
  }

  &__pendingButton {
    height: fit-content;
    margin-left: $spacer;
  }

  padding: 0;
  display: flex;
}

.spacedRows {
  :global(.row) + :global(.row) {
    margin-top: $spacer;
  }
}

.dropdownBody {
  border-top: 1px solid $sl-border-grey;
  overflow: auto;
  max-height: 300px;
}

.dropdownItem {
  white-space: nowrap;
  max-width: 400px;

  :global(.custom-control) {
    padding-left: 0;
  }
  :global(.form-group) {
    margin-bottom: 0;
  }

  &.highlightable {
    &:hover,
    &:focus-within {
      background-color: $sl-table-stripe;
    }
  }

  label {
    width: 100%;
    padding: $spacer * 0.75;
    padding-left: $spacer * 0.75 + 1.75rem;

    > span {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }

    &::before,
    &::after {
      top: 50%;
      transform: translateY(-50%);
      left: $spacer * 0.75;
    }
  }

  &:not(:first-child) {
    border-top: 1px solid $sl-aliceblue;
  }
}

.bankAccount {
  margin-bottom: $spacer * 1.5;
  .accountLabel {
    position: relative;
    padding-bottom: $spacer * 1.5;
    margin-top: $spacer * -1;
    margin-bottom: $spacer * 1.5;
    &::after {
      content: '';
      border-bottom: 1px solid $sl-deep-sea-blue;
      left: 50%;
      position: absolute;
      bottom: 0;
      transform: translateX(-50%);
      width: 80px;
    }
  }

  dl {
    margin-bottom: 0;

    dt {
      color: #737373;
    }
  }
}

.bankAccountModal {
  :global(.modal-content) {
    padding: $spacer * 2;
  }
}

.adminLinks {
  color: $sl-ocean-blue;
  & > :global(.btn-link) {
    border-width: 0;
    padding: 0 0 2px 8px;
    font-weight: 400;
  }
}

.headerCta {
  button {
    min-width: 10rem;
  }
}

.modalFooter {
  width: 100%;
  text-align: right;
}

.modalBody {
  &__text {
    margin-bottom: -1rem;
  }

  &__shareDocument {
    margin: -24px -48px -48px;
    padding: 0 0;
    overflow: hidden;
  }
}

.invitedCounterpartyButton {
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.removeCounterpartyButton {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 0.3rem;
  font-weight: bold;
  color: $ck-button-cancel;

  &:hover {
    cursor: pointer;
    color: $ck-button-cancel-onhover;
    text-decoration: underline;
  }
}

.dealHeaderTitle {
  margin: 0px 0px 0px 10px;
  max-width: 800px;
}

.dealName {
  margin: 0px 0px 20px 10px;
  font-size: 1.625rem;
  font-weight: 300;
}

.titleInput {
  display: inline-block;
  min-width: 10px;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2;

  &__title {
    display: block;
    float: left;
    min-width: 10px;
    min-height: 10px;
    outline: 1px solid $sl-ocean-blue;
    padding-right: 10px;
    max-width: 600px;
    word-wrap: break-word;
    overflow: visible;
    white-space: break-spaces;
    text-overflow: unset;
  }

  &:focus {
    outline: none;
    padding-right: 15px;
  }
}

.teamTitleInput {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  display: inline-block;
  min-width: 10px;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2;
}

.titleInputButtons {
  &__edit {
    color: $sl-ocean-blue;
    margin-bottom: 5px;
    overflow: visible;

    &:hover {
      cursor: pointer;
    }
  }

  &__confirm,
  &__cancel {
    margin-left: 15px;

    &:hover {
      cursor: pointer;
    }
  }
}

.dealModalInputWrapper {
  margin-top: $spacer;

  &__input {
    display: block;
    width: 100%;
    min-height: 90px;
    padding: 0.375rem 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    border: 1px solid #a2a2a2;
    border-radius: 0.125rem;
    resize: none;

    &:focus {
      border-color: #006c90;
      outline: 0;
      box-shadow: none;

      &::placeholder {
        visibility: hidden;
      }
    }
  }
}

.sharedDealInfo {
  background-color: blue;
}

#status-dropdown {
  border-radius: 100px !important;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: $sl-aliceblue !important;
  border-color: $sl-aliceblue;
}

.headerDealStatus {
  padding: 8px 16px;
  font-size: 14px;
  background-color: $sl-aliceblue;
  border-radius: 100px !important;
  display: flex;
  align-items: center;
  font-weight: normal;
}

.mobileHeaderBtn {
  height: 48px !important;
}

.mobileSearch {
  height: 48px !important;
  font-size: 14px;
  padding: 12px 16px 12px 42px !important;

  &__icon {
    left: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__container {
    width: 100%;
    margin-right: 16px;
  }
}

.dealDetailBody {
  height: calc(100vh - 123px - 1.5rem);
}
