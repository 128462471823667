.spinner_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;   /* Takes the full width of the parent container */
  height: 100%;  /* Takes the full height of the parent container */
  padding: 2px; /* Prevents the spinner from touching the container edges */
}

.spinner {
  width: 100%;
  height: auto;
  aspect-ratio: 1; /* Keeps width and height the same */
  max-width: 100%;
  max-height: 100%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;  /* Blue color for the spinner */
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
