.webviewer {
  height: calc(100vh - 50px);
  width: calc(100vw - 300px);
  display: none;

  &_view-uploaded {
    width: 100vw;
  }

  &-receiver {
    width: calc(100vw - 20px);
  }

  &-display {
    display: block;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: end;
}

div.Icon {
  color: unset !important;
}

//SIM-5713 Update Email buttons link and label
