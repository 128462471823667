@import '../../../../../../styles/colors';
@import '../../../../../../styles/bootstrap-variables';

.grant-access-button {
  background-color: $sl-table-stripe !important;
  margin: 0px $spacer/2;

  &:hover {
    background-color: #006c90 !important;
  }
}

.locked-label {
  align-self: center;
  color: $sl-ocean-blue;
  font-weight: 500;
  margin-right: $spacer;
}
