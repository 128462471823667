@import 'src/styles/_colors.scss';
@import 'src/styles/_bootstrap-variables.scss';


.attachmentSidebarWrapper {
  padding: 0.85rem 1.25rem;
  padding-bottom: 2rem;

  .tooltip {
    margin-left: 0.3rem;
  }
}

.attachmentItem {
  padding: 10px;
  display: flex;
  width: 100%;
  background-color: #fff;
  box-shadow: 2px 2px 4px #a8a8a8;
  position: relative;
  transition: opacity 0.15s ease;
  overflow: hidden;
  margin-bottom: 10px;
  align-items: center;
  height: 41px;

  &.canManipulate {
    &:hover {
      cursor: move;
  
      .dragHandle {
        display: initial;
        margin-right: 0.37rem;
      }
  
      .attachmentNumber {
        display: none;
      }
    }
  }

  &-dragging {
    opacity: 0.5;
  }

  .dragHandle {
    display: none;
  }


  .attachmentTitle {
    flex-grow: 1;
    display: inline-block;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;

    .filenameWrapper {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .attachmentIcon {
      margin-right: 3px;
    }
  }

  .attachmentActions {
    display: flex;
    align-items: center;

    .openAttachment {
      margin-right: 8px;
    }

    .removeAttachment {
      background: none;
      border: none;
      cursor: pointer;
      color: $ck-button-cancel;
      padding: 0;
    }
  }

  .attachmentNumber {
    display: inline-flex;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border: 1px solid;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 2px 0.5rem 2px 2px;
    flex: 0 0 20px;
  }

  // &:hover .dragHandle {
  //   display: block;
  // }

  // &:hover .attachmentNumber {
  //   display: none;
  // }
}

.dragIcon {
  width: 24px;
  color: $sl-medium-grey;
}

.removeAttachment {
  display: inline-flex;
  background-color: var(--ck-color-comment-remove-background);
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.noAttachments {
  border-radius: 8px;
  padding: 1rem 2rem;
  text-align: center;
  background-color: $sl-aliceblue-tint;
}