// Blues and blue-greys
$sl-almost-black: #18191b;
$sl-azure: #e4f6fb;
$sl-dark-grey-blue: #274156;
$sl-deep-sea-blue: #125b81;
$sl-ocean-blue: #006c90;
$sl-steel-grey: #6f878f;
$sl-light-blue: #a8cdd9;
$sl-aliceblue: #ddebef;
$sl-aliceblue-tint: #f2f6f8;
$sl-teal: #9ff4ea;
$sl-snow-flurry: #dbfed5;
$sl-whitesmoke: mix(white, $sl-aliceblue, 60%);

// Greens
$sl-greenish: #38a157;
$sl-hospital-green: #78d694;
$sl-confirmation-green: #6aa84f;
$sl-honeydew: #e2f1e7;

// Purples
$sl-perfume: #e8caf8;

// Reds
$sl-light-red: #f14949;
$sl-light-maroon: #984343;
$sl-dirty-pink: #d77373;
$sl-mistyrose: #fde0d4;
$sl-seashell: mix(white, $sl-mistyrose, 13%);
$sl-light-pink: #fef4f0;

// Oranges and yellows
$sl-corn-silk: #fff5dd;
$sl-wheat: #fdebc2;
$sl-sand: #f0ca70;
$sl-pale-orange: #ffaa52;
$sl-coral: #ff763e;
$sl-blood-orange: #ef5212;
$ck-button-cancel: #db3700;
$sl-gold: #f0ca7c;

// Greys
$sl-dark-deep-grey: #363636;
$sl-dark-grey-mobile: #363C48;
$sl-dark-grey: #737373;
$sl-grey: #606060;
$sl-background-grey: #fafafa;
$sl-medium-grey: #9a9898;
$sl-light-grey: #e3e3e3;
$sl-dark-gray: #a5a5a5;

// For specific uses
$sl-shadow: rgba(#092c37, 0.12);
$sl-border-grey: #d8d8d8;
$sl-table-stripe: mix(white, $sl-ocean-blue, 97%);
$sl-disabled-text: #a2a2a2;
$sl-disabled-button: #c5c5c5;
$sl-tree-explorer-sidebar: #f9fdff;
$sl-card-background-blue: #f3f7f9;
$sl-blue-button-onhover: #004f6a;
$sl-white: #fff;
$sl-table-row-onhover: #ececec;
$ck-button-cancel-onhover: #d43500;
$sl-gainsboro: #e0e0e0;

$signature-block-text: #000;

$sl-line-gray: #717782;
$sl-line-gray-light: #dddddd;
$sl-gray-label: #6f747e;