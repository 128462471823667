@import 'src/styles/_colors.scss';

.title {
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 15px;
  color: $sl-grey;
}

.buttons {
  margin: 10px 0 0;
  justify-content: right;

  &-add:disabled {
    opacity: 0.5 !important;
  }
}

.wrapper2 {
  display: flex;
  width: 100%;
  border: 1px solid #a8a8a8;
  border-bottom: none;
  border-collapse: collapse;
  position: relative;
  transition: opacity 0.15s ease;
  overflow: hidden;
  align-items: center;
  height: 41px;
}

.wrapper2:last-child {
  border-bottom: 1px solid #a8a8a8;
}

.button {
  min-width: unset;
  min-height: unset;
  background: transparent;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 1px;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  width: 26px;
  height: 26px;
  transition: background-color 0.15s ease;
  border-radius: 2px;

  &:disabled {
    cursor: unset;

    .icon {
      &-delete,
      &-reject-delete {
        color: $sl-light-grey;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }

  &:hover {
    background-color: $sl-light-grey;
  }
}

.content {
  padding: 3px;
  display: flex;
  min-width: 100%;
  transition: transform 0.2s linear;
  justify-content: space-between;
  align-items: center;

  &-deleting {
    transform: translate3d(-102%, 0, 0);
    overflow: hidden;
  }
}

.delete {
  display: flex;
  min-width: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: var(--ck-color-comment-remove-background);
  animation: fadeIn;
  animation-duration: 0.2s;

  &-title {
    margin-right: 10px;
    font-size: 16px;
    line-height: 17px;
  }

  &-buttons {
    margin: 0;
  }
}

@keyframes fadeIn {
  from {
    left: 100%;
  }
  to {
    left: 0;
  }
}

.icon {
  &-edit {
    color: $sl-dark-grey-blue;
  }

  &-delete {
    color: $ck-button-cancel;
  }

  &-approve {
    color: $sl-greenish;
  }

  &-reject-delete {
    color: $ck-button-cancel;
  }

  &-edit,
  &-delete,
  &-approve,
  &-reject-delete {
    opacity: 0.8;
  }

  &-edit:hover,
  &-delete:hover,
  &-approve:hover,
  &-reject-delete:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.email-text {
  padding: 6px 8px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.controls {
  display: flex;
  flex-wrap: nowrap;
  justify-items: flex-end;
}
