@import '../../../src/styles/colors.scss';
@import '../../../src/styles/bootstrap-variables';

@mixin modalStyles(
  $padding,
  $top,
  $maxWidth,
  $titleFontSize,
  $titleTextAlign,
  $titleLineHeight,
  $titleMargin,
  $titleFontWeight
) {
  z-index: 1040;
  padding: $padding;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: $top;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: none;
  box-shadow: 0 2px 4px 0 rgba(9, 44, 55, 0.12);
  max-height: 80% !important;
  max-width: $maxWidth;

  @media (min-width: 576px) {
    max-height: calc(100vh - 3.5rem);
  }

  .propdocsModal_content__title {
    font-size: $titleFontSize;
    text-align: $titleTextAlign;
    line-height: $titleLineHeight;
    margin: $titleMargin;
    font-weight: $titleFontWeight;
  }

  .propdocsModal_content {
    width: 100%;
    max-height: 100%;
  }

  .propdocsModal_content__closeButton {
    position: fixed;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .propdocsModal_content__closeIcon {
    align-self: center;
    width: $font-size-base;
    height: $font-size-base;
    opacity: 0.5;

    &:hover {
      opacity: 0.7;
    }
  }
}

.sm-modal {
  @include modalStyles(3em, 35%, 320px, 1.25rem, center, 1.2, 1rem 0 0 0, 600);
}

.md-modal {
  @include modalStyles(3em, 35%, 520px, 1.4rem, left, 1.2, 1rem 0 0 0, 600);
}

.lg-modal {
  @include modalStyles(4em, 50%, 700px, 1.4rem, left, 1.5, 0, 500);
}

.propdocsModal_backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #274156;
  opacity: 0.56;
}

.fade {
  opacity: 0;
  transition: opacity 200ms linear;
}

.show {
  opacity: 1;
}

.backdrop.fade.show {
  opacity: 0.5;
}

.dialog {
  position: absolute;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
}
