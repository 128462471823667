@import '../../../../styles/bootstrap-variables';
@import '../../../../styles/colors';

.lease-document-wizard-header-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 220px);
  font-size: 1rem;
}

.lease-document-wizard-header-title__label {
  padding: $input-padding-y $input-padding-x;
  border-radius: $border-radius;
  border: 1px solid #fff;
  font-weight: 500;
  transition: border 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.lease-document-wizard-header-title__label--can-edit {
  &:hover {
    border-color: $sl-border-grey;
    cursor: text;
  }
}

.lease-document-wizard-header-title__input {
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:focus {
    position: static;
    z-index: 0;
    opacity: 1;
    + .lease-document-wizard-header-title__label {
      display: none;
    }
  }
}
