@import '../../lease-wizard/lease-wizard-shared';
@import '../../../styles/colors';

$lease-editor-border-colour: RGB(0 0 0 / 5%);

@mixin button-handlers {
  margin: 3px 7px;
  padding: 0 8px;
  font-size: 10pt;
  width: 90px;
  min-height: 20px;
  height: 25px;
  justify-content: center;
  border-radius: 4px;

  &:active {
    border: none;
  }
}

* {
  --ck-inline-annotation-container-width: 300px;
  --ck-inline-annotation-container-max-height: 400px;
}

.header-footer-editor {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  max-height: 200px;

  ul.ck-list {
    max-height: 180px;
    overflow-y: scroll;
  }

  button[data-row='3'],
  button[data-row='4'],
  button[data-row='5'],
  button[data-row='6'],
  button[data-row='7'],
  button[data-row='8'],
  button[data-row='9'],
  button[data-row='10'] {
    display: none !important;
  }
}

.header-footer-editor__with-slide-out {
  margin-left: 0px;
}

.header-footer-editor__lock-editor-warning {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $sl-dirty-pink;
  margin-top: -16px;
  height: 40px;
  color: $sl-whitesmoke;
}

.header-footer-editor__editable-container {
  position: relative;
  background-color: $sl-background-grey;
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  z-index: 0;
  min-height: 160px;
  max-height: 160px;
  overflow: hidden;

  .ck-content.ck-editor__editable,
  .lease-editor__sidebar {
    align-items: stretch;
    flex-direction: column;
    flex-grow: 1;
    border: none;
  }
  .header-footer-editor__sidebar.narrow {
    min-width: 65px;
    width: 55px;
  }
}

#HeaderEditor .ck.ck-editor__editable,
#HeaderEditor .ck.ck-editor__editable_inline {
  max-width: calc(7.1in + 2px);
  min-width: calc(7.1in + 2px);
  height: 1in;
  // max-height: 1in;
  padding: 1.1in;
  padding-top: $spacer/4;
  padding-bottom: $spacer;
  border: 1px solid $lease-editor-border-colour;
  box-shadow: $lease-document-box-shadow;
  background-color: white;

  figcaption {
    width: auto;
    background: none;
    border: 0 none;
    box-shadow: none;
    min-height: auto;
  }

  span.form-connector-value.ck-widget {
    background-color: $sl-teal;
    &:hover {
      outline-color: $sl-hospital-green;
    }
  }

  img {
    max-height: 70px;
  }
}

#HeaderEditor .ck.ck-editor__editable,
#HeaderEditor .ck.ck-editor__editable_inline {
  max-width: calc(8.6in);
  min-width: calc(8.6in);
  //height: 1in;
  // max-height: 1in;
  min-height: 120px;
  height: auto;
  overflow: hidden;
  padding-top: $spacer;
  border: 1px solid $lease-editor-border-colour;
  box-shadow: none;
  background-color: white;

  figcaption {
    width: auto;
    background: none;
    border: 0 none;
    box-shadow: none;
    min-height: auto;
  }

  span.form-connector-value.ck-widget {
    background-color: $sl-teal;
    &:hover {
      outline-color: $sl-hospital-green;
    }
  }
}
.header-footer-editor__sidebar {
  min-width: 35px;
  padding: 0 10px;
}
.editors-holder {
  height: 100%;
  width: 100%;
  display: flex;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: 1px solid $lease-editor-border-colour;
  box-shadow: $lease-document-box-shadow;
  outline: 1px solid transparent;
}

.ck.ck-toolbar {
  border: none;
}

.header-footer-editor {
  .ck.ck-sidebar--narrow {
    .ck-sidebar-item {
      justify-content: left;

      .ck-annotation-counter__badge {
        z-index: auto;
      }
      .ck-icon.ck-button__icon {
        position: relative;
        left: -4px;
        top: 4px;
      }
    }
  }
}

.ck-body-wrapper {
  .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
  }
}

.ck.ck-button.ck-button_with-text .ck-button__icon {
  height: 20px;
  width: 24px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.editor-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
}

.ck.ck-editor {
  max-width: 800px;
}

#revision-viewer-container {
  display: none;
}

.editor-container > .ck-editor {
  position: relative;
  width: 950px;
}

.header-footer-editor__toolbar {
  width: 100%;
}

.editor-container .ck-editor__editable_inline {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sidebar-container {
  position: relative;
  border: 1px solid var(--ck-color-toolbar-border);
  margin-left: -1px;
}

.ck.ck-button.ck-off.ck-button_with-text.ck-comment__input-actions--cancel {
  @include button-handlers;
  border: solid 1px $sl-ocean-blue;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(137, 159, 207, 0.5);
  color: $sl-ocean-blue;

  &:hover {
    background-color: $sl-table-stripe;
  }
}

.ck.ck-button.ck-off.ck-disabled.ck-button_with-text.ck-comment__input-actions--submit {
  color: $sl-almost-black;
  background-color: transparent;
  box-shadow: none;
}

.ck.ck-button.ck-off.ck-button_with-text.ck-comment__input-actions--submit {
  @include button-handlers;
  border-radius: 4px;
  background-color: $sl-ocean-blue;
  color: $sl-background-grey;
  box-shadow: 0px 1px 1px rgba(25, 38, 66, 0.5);

  &:hover {
    background-color: $sl-blue-button-onhover;
  }
}

.ck .ck-comment__input-actions.ck-comment__input-actions--active {
  margin-top: 8px;
}

.ck-thread__remove-confirm {
  background-color: $sl-disabled-text !important;
}

button.ck.ck-button.ck-off.ck-thread__remove-confirm-submit {
  @include button-handlers;
  color: $sl-background-grey;
  background-color: $ck-button-cancel;

  &::before {
    content: 'Delete';
  }

  .ck-icon.ck-button__icon {
    display: none;
  }

  &:hover {
    background-color: $ck-button-cancel-onhover;
    cursor: pointer;
  }
}

button.ck.ck-button.ck-off.ck-thread__remove-confirm-cancel {
  @include button-handlers;
  color: $sl-ocean-blue;
  background-color: $sl-background-grey;

  &::before {
    content: 'Cancel';
  }

  .ck-icon.ck-button__icon {
    display: none;
  }

  &:hover {
    background-color: $sl-card-background-blue;
    cursor: pointer;
  }
}

.ck.ck-button.ck-off.ck-comment--resolve {
  display: none !important;
}

.revision-viewer {
  $root: &;

  &__container {
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: #ffffff;

    &_tenant {
      padding-left: 0;
    }

    &_no-editing-name {
      .ck.ck-labeled-field-view > .ck.ck-labeled-field-view__input-wrapper {
        pointer-events: none;
      }

      .ck.ck-revision-history-sidebar__revision-wrapper
        .ck-revision-history-sidebar__revision.ck-revision-history-sidebar__revision_selected {
        .ck-revision-history-sidebar__revision__actions {
          display: none;
        }
      }
    }

    .editor-container {
      height: 100%;
    }

    #{$root}__sidebar {
      padding-bottom: 60px;
    }

    #{$root}__editor {
      max-width: calc(750px + 2px);
      min-width: calc(750px + 2px);
      margin-top: 38px;
    }

    #{$root}__editor.ck.ck-editor__editable_inline {
      padding-left: 20px;
      box-sizing: border-box;
      padding-right: 106px;
    }
  }
}

.confirmation-action-modal-custom > div > div {
  padding: 2em;
}

.spinner__document-preparing {
  z-index: 1;
}

.ck.ck-tooltip.suggestion-tooltip .ck-tooltip__text {
  //color: #000;
  display: block;
  max-width: 150px !important;
  font-size: 0px !important;
}

.ck.ck-tooltip.suggestion-tooltip {
  width: 150px;
  height: 75px;
  color: #000;
  background-color: #ddebef;
  overflow-wrap: anywhere;
  white-space: wrap !important;
}
.ck.ck-tooltip.suggestion-tooltip .ck-tooltip__text:after {
  font-size: 12px;
  content: 'Your counterparty must \00000aaccept or discard \00000asuggestions made by \00000ayour team.';
  white-space: pre;
  color: #000;
}

.ck-content .table thead {
  display: table-row-group;
}

.ck-content .table {
  margin: 0.9em auto;
  display: table;
}

#HeaderEditor .ck.ck-editor__editable > .ck-widget.ck-widget_with-selection-handle:first-child,
#HeaderEditor .ck.ck-editor__editable blockquote > .ck-widget.ck-widget_with-selection-handle:first-child {
  margin-top: 10px;
}
 //#Editor figure.table {
 //  width: unset;
 //}
.ck.ck-editor__editable.ck-pagination-view .ck-table-column-resizer {
  display: none;
}
.ck.ck-balloon-panel.ck-balloon-panel_arrowless.ck-balloon-panel_visible {
  z-index: 20000;
}

.ck.ck-balloon-panel.ck-balloon-panel_arrow_n.ck-balloon-panel_visible.ck-balloon-panel_with-arrow.ck-toolbar-container {
  z-index: 9000;
}

ol {
  padding-left: 15px;
}

#HeaderEditor {
  margin-top: $spacer;
}

div.pageNumber {
  text-align: center;

  input:hover {
    cursor: unset;
  }
}
