@import '../../../styles/bootstrap-variables';
@import '../../../styles/colors';

$sub-nav-left: 0;
$sub-nav-width-collapsed: 20px;
$sub-nav-width-expanded: 300px;
$inset-nav-box-shadow: inset 2px -1px 4px $sl-shadow;
$nav-box-shadow: 2px 5px 4px $sl-shadow;

.subNavWrapper {
  position: relative;
  top: 0px;
  left: $sub-nav-left;
  display: flex;
  z-index: 2;
}

.subNav {
  position: relative;
  background-color: $sl-whitesmoke;
  height: calc(100vh - 50px);
  width: $sub-nav-width-expanded;
  transition: 200ms width;
  box-shadow: $inset-nav-box-shadow, $nav-box-shadow;
  display: flex;
  flex-direction: column;

  @supports (backdrop-filter: blur(5px)) {
    background-color: $sl-tree-explorer-sidebar;
    backdrop-filter: blur(5px);
  }
}

.subNavMask {
  overflow: hidden;
}

.subNavContent {
  min-width: $sub-nav-width-expanded;
}

.subNavToggle {
  background-color: transparent;
  background-image: url('../../../shared/icons/subnav-expand.svg');
  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(-50%);
  width: 33px;
  height: 33px;
  padding: 0;
  border-radius: 50%;
  border: 0;
  transition: 200ms box-shadow;
  z-index: 100;

  &[aria-expanded='true'] {
    background-image: url('../../../shared/icons/subnav-collapse.svg');
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 4px 1px $sl-ocean-blue;
  }
}

.collapsed {
  &.subNav {
    width: 20px;
  }
  .subNavContent {
    visibility: hidden;
    transition: visibility 0s 200ms;
  }
}

.contentHeader {
  background: linear-gradient(235deg, transparent, white 80%),
    url('../../../shared/icons/cube-texture-light-blue.svg') center/140px,
    linear-gradient(to bottom right, white 20%, #ebf2f7 80%);
  padding: 0.8rem 16px;
  box-shadow: 0 2px 4px $sl-shadow;

  & > :global(.container) {
    padding-left: $sub-nav-width-collapsed * 2;
  }
}

.roleOption {
  // The !important flag in here is to override .dropdown-item
  display: flex !important;
  align-items: center;
}

.roleIcon {
  background-color: #c7e4ee;
  color: $sl-ocean-blue;
  width: 2em;
  height: 2em;
  overflow: hidden;
  border-radius: 50%;
  margin-right: $spacer;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;

  & svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.closeButton {
  background: transparent;
  border: none;
  padding: 16px 0;
  color: $sl-disabled-text;
  transition: color 0.3s ease;
  &:hover,
  &:focus {
    color: $sl-ocean-blue;
  }
}

.closeIcon {
  align-self: center;
  width: $font-size-base;
  height: $font-size-base;
}
