@import 'src/styles/_colors.scss';

$header-height: 50px;
$inspectorNavbox-height: 30px;

.wrapper {
  padding: 0.85rem 1.25rem;

  &-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.templates-container {
  padding: 16px 0;
  height: calc(100vh - #{$header-height + $inspectorNavbox-height * 5});
  overflow: auto;
}

.signers-title {
  margin-bottom: 5px;
  font-size: 16px;
}

.title {
  padding: 0;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 400;
  color: $sl-grey;
}

.buttons {
  margin: 10px 0 0;
  justify-content: right;

  &-add:disabled {
    opacity: 0.5 !important;
  }
}

.searchInput {
  width: 100%;
}
