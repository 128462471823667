@import '../../../../../../styles/colors';
@import '../../../../../../styles/bootstrap-variables';

.grant-access-button {
  background-color: $sl-table-stripe !important;
  margin: 0px $spacer/2;

  &:hover {
    background-color: #006c90 !important;
  }
}
