@import '../../styles/colors';
@import '../../styles/bootstrap-variables';

$document-table-title-height: 105px;

.documentButtons {
  gap: 15px;
  flex-wrap: wrap;

  @media only screen and (max-width: $mobile-width) {
    flex-direction: column-reverse;

    &__btns {
      width: 100%;

      & > * {
        width: 100%;
      }

      & button {
        justify-content: center !important;
        height: 48px !important;
      }
    }
  }
}

.cardTitle {
  font-size: $font-size-nominal-default;
  margin-bottom: 0;
}

.documentTableCard {
  overflow: initial;
  position: relative;
  max-height: calc(100vh - 14rem);
  justify-content: center;

  .new-document-button-container {
    padding-right: 30px;
    display: flex;
    justify-content: right;
  }
}

.createDocumentContainer {
  padding-top: $spacer;
  text-align: -webkit-center;
}

.createDocumentDropdown {
  margin-top: 20px;
}

button {
  &.createDocumentButton {
    width: auto;
    margin-top: $spacer;
    padding: 8px 32px;
  }

  &.new-document-button {
    padding: 5px 16px;
  }
}

.documentDataTable {
  height: calc(100vh - ($spacer * 10) - $document-table-title-height);
  overflow-y: auto;

  table {
    text-indent: $spacer;
  }

  @media only screen and (max-width: $mobile-width) {
    padding: 0px 16px !important;
  }
}

.documentDropdownContainer {
  width: 100%;
  display: flex;
}

.documentDropdown {
  width: fit-content;
}

.documentStatus {
  text-transform: capitalize;
}

.documentTitle {
  margin-left: -20px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  width: 290px;
}

.sendDocumentModal {
  margin: -80px -50px;
}

.dottedDropdown > svg {
  display: block;
  pointer-events: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: unset;
  fill: $sl-steel-grey;
  stroke: $sl-steel-grey;
  color: $sl-whitesmoke;

  &:hover {
    color: $sl-steel-grey;
    fill: $sl-whitesmoke;
  }

  &:active {
    color: $sl-whitesmoke;
    stroke: $sl-ocean-blue;
    fill: $sl-ocean-blue;
  }

  &[aria-expanded='true'] {
    color: $sl-whitesmoke;
    stroke: $sl-ocean-blue;
    fill: $sl-ocean-blue;
  }
}

.internalApprovalTooltipTitle {
  font-weight: 500;
  text-align: left;
  margin: 0 15px;
}

.searchInboxBox {
  min-width: 200px;

  @media only screen and (max-width: $mobile-width) {
    height: 48px !important;

    input {
      height: 100% !important;
      padding: 12px 16px 12px 48px !important;
    }

    svg {
      left: 16px !important;
    }
  }
}

.historyBtn {
  padding: 8px !important;
  width: 48px !important;
  height: 48px !important;
}