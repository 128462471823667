.root {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  line-height: 1;
}

.initials {
  color: black;
  margin: auto;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
