@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

.root {
  list-style-type: none;
  border-collapse: collapse;
  padding: 0;
  display: table;
  position: relative;
  max-width: 700px;
  width: 100%;

  & > li {
    display: table-row;

    &:not(:first-child) {
      border-top: 1px solid $sl-aliceblue;
    }

    & > * {
      display: table-cell;
      padding: 0.5 * $spacer;
    }
  }

  @media only screen and (max-width: $mobile-width) {
    margin-top: 16px;
    width: 100%;

    .row {
      display: flex;
      align-items: center;
    }

    .iconContainer {
      padding: 0;
      width: 24px;
    }

    .textContainer p {
      display: none;
    }

    .stateContainer {
      margin-left: auto;
    }
  }
}

.iconContainer {
  text-align: center;
  width: 50px;
}

.stateContainer {
  text-align: right;
  vertical-align: middle;
}
