@import '../../styles/bootstrap-variables';

.root {
  border: thick solid white;
  background: black;
  color: white;
  position: absolute;
  top: 0;
  left: -100vw;
  padding: 0.5em;
  overflow: hidden;
  width: 1px;
  height: 1px;
  z-index: $zindex-popover;

  &:focus,
  &:active {
    width: auto;
    height: auto;
    overflow: visible;
    left: 0;
  }
}
