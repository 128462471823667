@import '../../../../styles/colors';

.send-button {
  transition: opacity 0.15s ease;

  &:disabled {
    opacity: 0.5;
    background-color: $sl-blood-orange;
  }

  &:disabled:hover {
    background-color: $sl-blood-orange;
  }
}

.title {
  text-align: left !important;
}
