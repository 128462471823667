@import '../../../../styles/bootstrap-variables';
@import '../../../../styles/colors';

.mobileFilterForm {
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobileFilterBtns {
  display: flex;
  flex-wrap: nowrap;
  padding: 0px 10px;
  bottom: 24px;
  width: 100%;
  background: white;
  margin-bottom: 24px;
  z-index: 100;

  button {
    width: 100%;
    padding: 12px!important;
    margin-left: 6px;
    margin-right: 6px;
  }
}