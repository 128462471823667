@import '../../../styles/bootstrap-variables';
@import '../../../styles/colors';

$sub-nav-left: 55px;
$sub-nav-width-collapsed: 20px;
$sub-nav-width-expanded: 300px;
$inset-nav-box-shadow: inset 2px -1px 4px $sl-shadow;
$nav-box-shadow: 2px 5px 4px $sl-shadow;

.documentNavigationBar {
  position: relative;
  background-color: $sl-whitesmoke;
  height: 100vh;
  width: $sub-nav-left;
  transition: 200ms width;
  box-shadow: $inset-nav-box-shadow, $nav-box-shadow;
  display: flex;
  flex-direction: column;

  @supports (backdrop-filter: blur(5px)) {
    background-color: $sl-tree-explorer-sidebar;
    backdrop-filter: blur(5px);
  }

  &__icon {
    padding: $spacer 0;
    color: $info;
    font-size: 0.75rem;
    align-self: center;
    text-align: center;
    border-style: none solid;
    border-width: 4px;
    border-color: transparent;
    width: 100%;

    svg {
      fill: $info;
    }

    &.enabled:hover,
    &.active {
      cursor: pointer;
      border-left-color: $sl-ocean-blue;
      color: $sl-ocean-blue;

      svg {
        fill: $sl-ocean-blue;
      }
    }
  }
}

.subNavMenuWrapper {
  position: relative;
  left: 0;
  display: flex;
  z-index: 10;
}

.subNav {
  position: relative;
  background-color: $sl-whitesmoke;
  height: 100vh;
  width: $sub-nav-width-expanded;
  transition: 200ms width;
  box-shadow: $inset-nav-box-shadow, $nav-box-shadow;
  display: flex;
  flex-direction: column;

  @supports (backdrop-filter: blur(5px)) {
    background-color: $sl-tree-explorer-sidebar;
    backdrop-filter: blur(5px);
  }
}

.subNavMask {
  overflow: hidden;
  overflow-y: auto;
}

.subNavContent {
  min-width: $sub-nav-width-expanded;
}

.subNavToggle {
  background-color: transparent;
  background-image: url('../../../shared/icons/subnav-collapse.svg');
  position: absolute;
  margin-top: 37px;
  left: 100%;
  transform: translateX(-50%);
  width: 33px;
  height: 33px;
  padding: 0;
  border-radius: 50%;
  border: 0;
  transition: 200ms box-shadow;
  z-index: 100;

  &[aria-expanded='true'] {
    background-image: none;
  }

  &__hidden {
    display: none;
  }
}

.collapsed {
  &.subNav {
    width: 0px;
  }
  .subNavContent {
    visibility: hidden;
    transition: visibility 0s 200ms;
  }
}

.contentHeader {
/*  background: linear-gradient(235deg, transparent, white 80%),
    url('../../../shared/icons/cube-texture-light-blue.svg') center/140px,
    linear-gradient(to bottom right, white 20%, #ebf2f7 80%);*/
  padding: 0.8rem 16px;
  box-shadow: 0 2px 4px $sl-shadow;

  & > :global(.container) {
    padding-left: $sub-nav-width-collapsed * 2;
  }
}

.roleOption {
  // The !important flag in here is to override .dropdown-item
  display: flex !important;
  align-items: center;
}

.roleIcon {
  background-color: #c7e4ee;
  color: $sl-ocean-blue;
  width: 2em;
  height: 2em;
  overflow: hidden;
  border-radius: 50%;
  margin-right: $spacer;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;

  & svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.closeButton {
  background: transparent;
  border: none;
  padding: 16px 0;
  color: $sl-disabled-text;
  transition: color 0.3s ease;
  &:hover,
  &:focus {
    color: $sl-ocean-blue;
  }
}

.closeIcon {
  align-self: center;
  width: $font-size-base;
  height: $font-size-base;
}