@import '../../../../../../styles/colors';
@import '../../../../../../styles/bootstrap-variables';

.requestReviewDropdown {
  text-align: -webkit-center;
  :global(.dropdown-header) {
    font-weight: 600;
    color: #333;
  }
  :global(.dropdown-item) {
    padding-left: 24px;

    &.signInButton {
      padding-left: 16px;
      font-weight: 700;
    }
  }
}

.dropdownTitle {
  display: block;
  font-weight: 500;
  padding-right: 42px;
}

.requestInternalApprovalModalContainer {
  margin: 0;
  padding: 0;
}

.createDocumentInputForm {
  margin-top: -20px;
}

.requestInternalApprovalButtonsGroup {
  display: flex;
  padding-top: 4px;
  width: 100%;
  justify-content: end;
}

.setApprovalRequirementToggle {
  display: flex;
  margin-top: $spacer;
  justify-content: space-between;

  &__label {
    margin-right: $spacer;
    padding-top: $spacer/3;
  }

  &__tooltip {
    display: inline-block;
    transform: translateY(-10px);
  }
}

.teamMembersDropdown {
  width: 100%;
}

.requestReviewContainer {
  width: 100%;
  display: flex;
  justify-content: right;
}

.handleApprovalRequestButtonsGroup {
  button {
    min-width: 100px;
    margin-right: $spacer/2;
  }
}

.teamMembersDropdown__container {
  width: 300px;
}

.manageDealTeamLink {
  color: #006c90;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    color: #003344;
    text-decoration: underline;
  }
}

.emailInputField {
  * {
    margin: 0 !important;
  }
}

.includeAttachmentsFormGroup {
  display: flex;
  align-items: left;
  margin-top: 0;
}

.includeAttachmentsCheckbox {
  width: auto !important;
  margin-right: 8px;
  display: inline-flex !important;
  align-items: flex-start;

  &:checked {
    background-color:  $sl-ocean-blue;
    border-color:  $sl-ocean-blue;
  }

    // appearance: none !important;
    // -webkit-appearance: none;
    // display: inline-flex !important;
    // align-content: center;
    // justify-content: center;
    // font-size: 1rem;
    // padding: 0.1rem;
    // border: 1px solid $sl-ocean-blue;
    // border-radius: 0rem;
    // width: auto;
    // margin-right:8px;

    // &::before {
    //   content: "";
    //   width: 10px;
    //   height: 10px;
    //   clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
    //   transform: scale(0);
    //   background-color: $sl-ocean-blue;
    // }

    // &:checked::before {
    //   transform: scale(1);
    // }
}

.includeAttachmentsLabel {
  margin: 0;
  display: inline-flex !important;;
}
