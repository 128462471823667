@import '../../../styles/colors';

.templateSearch {
  width: 100%;
}

.selectedTemplateButton {
  min-width: max-content;
}

button.createDocumentButton {
  width: auto;
  padding: 8px 32px;
}

.documentTitleInput {
  margin: 0px 16px;
  height: 36px;
  flex: auto;
}

.templateModalFooterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modalTableContainer {
  max-height: 420px;
  margin: 16px 0px;
  overflow-y: scroll;
}

.templateInputForm {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;

  * {
    padding: 0px 8px;
  }
  &__input {
    flex-grow: 1;
  }
}

.createBlankDocumentModal {
  padding: 20px 0 0 0;
}

.createBlankDocumentHeader {
  text-align: center;
  font-size: large;
  margin: 5px -5px;
}

.createDocumentInputForm {
  margin-top: -20px;
}

.createDocumentButtonsGroup {
  display: flex;
  padding-top: 4px;
  width: 100%;
  justify-content: flex-end;
}

.createExpressDocumentModal {
  padding: 20px 0 0 0;
}

.createExpressDocumentModal_subheader {
  margin-top: 1em;
  margin-bottom: 0;
}

.createExpressDocumentModal_submitButton {
  margin-top: 2em;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
