@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

.dropdown {
  &__toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    font-size: 18px;
    font-weight: 600;

    svg {
      color: $sl-ocean-blue;
    }
  }

  &__body {
    padding: 0px;
    transition: all .3s ease;
    height: 0;
    overflow: hidden;

    &__open {
      height: auto;
      padding: 0px 16px;
    }
  }
}

.hasBorder {
  border-bottom: 1px solid #DDD;
}