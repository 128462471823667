@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

.dropdownBody {
  border-top: 1px solid $sl-border-grey;
  overflow: auto;
  max-height: 330px;
  min-width: 210px;
}

.dropdownItem {
  white-space: nowrap;

  :global(.custom-control) {
    padding-left: 0;
  }
  :global(.form-group) {
    margin-bottom: 0;
  }

  &.highlightable {
    &:hover,
    &:focus-within {
      background-color: $sl-table-stripe;
    }
  }

  label {
    width: 100%;
    padding: $spacer * 0.75;
    padding-left: $spacer * 0.75 + 1.75rem;

    > span {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }

    &::before,
    &::after {
      top: 50%;
      transform: translateY(-50%);
      left: $spacer * 0.75;
    }
  }

  &:not(:first-child) {
    border-top: 1px solid $sl-aliceblue;
  }
}
.dropdownTitleOrange {
  border-color: $sl-blood-orange !important;
  font-weight: 500 !important;
  &:focus {
    box-shadow: none !important;
  }
}

.dropdownTitle {
  border-color: $sl-ocean-blue !important;
  font-weight: 500 !important;
  &:focus {
    box-shadow: none !important;
  }
}

.toggleIcon {
  padding: 1rem 0;
  margin-top: 1rem;
  border-style: none solid;
  border-width: 4px;
  border-color: transparent;
  color: #a2a2a2;
}

.dropdownToggle {
  justify-content: space-between;
}

.dropdownTogglePeople {
  justify-content: space-between;

  @media only screen and (max-width: $mobile-width) {
    width: 100%;
    display: flex;
    justify-content: center !important;
    background-color: white !important;
    color: $sl-ocean-blue !important;
    border-color: $sl-ocean-blue !important;
    font-weight: bold !important;

    svg {
      display: none;
    }
  }
}