@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

$nav-width: 90px;
$nav-height: 460px;
$header-label-height: 85px;
$mobile-header-label-height: 64px;
$sub-nav-width-collapsed: 20px;
$sub-nav-width-expanded: 190px;
$nav-box-shadow: 2px 0 4px $sl-shadow;

.root {
  display: flex;
}

:global(#main-content) {
  margin-left: $nav-width;
  width: 100%;
  overflow-y: auto;
  height: auto;

  & > :global(.container) {
    padding-left: $sub-nav-width-collapsed * 0.7;
  }

  @media only screen and (max-width: $mobile-width) {
    margin-left: 0;
  }
}

.nav,
.settingsNav {
  position: fixed;
  left: 0;
  top: 0;
  flex: none;
  width: $nav-width;
  min-height: 100vh;
  box-shadow: $nav-box-shadow;
  background: $sl-aliceblue;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  z-index: 1;
}

.settingsNav {
  margin-left: $nav-width;
  margin-top: $nav-height;
  background-color: $sl-aliceblue;
  box-shadow: inset $nav-box-shadow, $nav-box-shadow;
  min-height: fit-content;
  width: auto;
  text-align: left;
}

.subnavWrapper {
  visibility: hidden;

  &.showSubnav {
    visibility: visible;
  }
}

.mobileSubnavWrapper {
  display: block;
  height: 0;
  overflow: hidden;

  &.showSubnav {
    height: auto;
  }
}

.item,
.subnavItem,
.settingsItem {
  padding: $spacer 0;
  margin-top: $spacer;
  border-style: none solid;
  border-width: 4px;
  border-color: transparent;
  color: $sl-dark-gray;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: $sl-white;
  }

  &:hover,
  &:focus,
  &.isCurrent {
    border-right-color: $sl-ocean-blue;
    color: $sl-ocean-blue;

    & .label {
      visibility: visible;
    }
  }

  &__signout {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.workspacesItem {
  border-style: none solid;
  border-color: transparent;
  color: $sl-ocean-blue;
  padding-right: $spacer/2;
  padding-bottom: 2px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.subnavItem {
  margin: 0;
}

.settingsItem {
  &:hover,
  &:focus,
  &.isCurrent {
    border-right-color: transparent;
    color: $sl-ocean-blue;

    & .arrowRight {
      visibility: visible;
    }
  }
}

.arrowRight {
  visibility: hidden;
  padding-left: $spacer/2;

  &:hover,
  &.isCurrent {
    visibility: visible;
    color: $sl-ocean-blue;
  }
}

.label {
  font-size: 0.75rem;
  visibility: hidden;
}

.subnavLabel {
  font-size: 0.75rem;
  padding: 0px $spacer * 2 0px $spacer/2;
}

.header {
  background-color: $sl-aliceblue;
  height: $header-label-height;
  margin: 0;

  & a {
    &,
    &:hover {
      color: inherit;
    }
  }

  @media only screen and (max-width: $mobile-width) {
    height: $mobile-header-label-height;
  }
}

.subNavWrapper {
  position: fixed;
  top: 0;
  left: $nav-width;
  display: flex;
  width: $sub-nav-width-collapsed;
  z-index: 2;
}

.workspacesWrapper {
  max-height: 72.3px;
}

.workspacesMenu {
  max-height: 400px;
  background-color: rgba(241, 247, 249, 0.62) !important;
  margin-top: 3px !important;
  margin-left: -1px !important;
  border: none !important;
  box-shadow: inset 2px 0 4px rgb(9 44 55 / 12%), 2px 0 4px rgb(9 44 55 / 12%) !important;

  @supports (backdrop-filter: blur(5px)) {
    background-color: rgba($sl-whitesmoke, 0.62);
    backdrop-filter: blur(5px);
  }
}

.workspaceOption,
.workspaceOption__newWorkspace {
  padding: $spacer * 0.75 $spacer !important;
  border-width: 4px !important;
  color: #a2a2a2 !important;
  font-size: 0.875rem;
  border-top: none !important;

  &__active,
  &:hover {
    padding: $spacer * 0.75 $spacer !important ;
    border: none !important;
    color: $sl-ocean-blue !important;
    font-size: 0.875rem !important;
    font-weight: bold !important;
  }

  &:focus {
    background-color: unset !important;
  }
}

.workspaceOption__newWorkspace {
  color: $sl-ocean-blue !important;
  font-weight: bold !important;
  border-top: 1px solid $sl-table-row-onhover !important;

  &:hover {
    border-top: 1px solid $sl-table-row-onhover !important;
  }
}

.subNav {
  position: absolute;
  background-color: $sl-ocean-blue;
  min-height: 100vh;
  transition: 200ms width;
  box-shadow: inset $nav-box-shadow, $nav-box-shadow;
}

.hiddenSubNav {
  min-height: unset;
}

.subNavMask {
  overflow-x: hidden;
}

.subNavContent {
  min-width: $sub-nav-width-expanded;
}

.subNavToggle {
  background-color: transparent;
  background-image: url('../../shared/icons/subnav-expand.svg');
  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(-50%);
  width: 33px;
  height: 33px;
  padding: 0;
  border-radius: 50%;
  border: 0;
  transition: 200ms box-shadow;

  &[aria-expanded='true'] {
    background-image: url('../../shared/icons/subnav-collapse.svg');
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 4px 1px $sl-ocean-blue;
  }
}

.collapsed {
  &.subNav {
    width: 20px;
  }
  .subNavContent {
    visibility: hidden;
    transition: visibility 0s 200ms;
  }
}

.contentBody {
  margin-top: 2.5 * $spacer;
  padding-right: 2.5 * $spacer;
  padding-left: 2.5 * $spacer;
  height: calc(100vh - $header-label-height - 2.5*$spacer);
  width: 100%;

  @media only screen and (max-width: $mobile-width) {
    height: calc(100dvh - $mobile-header-label-height - $spacer);
    margin-top: 16px;
    padding: 0px 16px;
  }
}

.contentHeader {
  padding: 1rem 0;
  height: $header-label-height;
  box-shadow: 0 2px 4px $sl-shadow;
  display: flex;
  align-items: center;

  & > :global(.container) {
    padding-left: $sub-nav-width-collapsed;
  }

  &__withSubheader {
    padding: 1rem 0;
    box-shadow: 0 2px 4px $sl-shadow;
    min-height: $header-label-height;
  }

  @media only screen and (max-width: $mobile-width) {
    height: $mobile-header-label-height;

    &__withSubheader {
      padding: 1rem 0;
      box-shadow: 0 2px 4px $sl-shadow;
      min-height: $mobile-header-label-height;
    }
  }
}

.headerTitle {
  display: inline-block;
  min-width: 10px;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: $mobile-width) {
    font-size: 24px;
    font-weight: 700;
  }
}

.roleOption {
  // The !important flag in here is to override .dropdown-item
  display: flex !important;
  align-items: center;
}

.roleIcon {
  background-color: #c7e4ee;
  color: $sl-ocean-blue;
  width: 2em;
  height: 2em;
  overflow: hidden;
  border-radius: 50%;
  margin-right: $spacer;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;

  & svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.indicatorContainter {
  display: flex;
  justify-content: right;

  @media only screen and (max-width: $mobile-width) {
    display: none;
  }
}

.moreContainer {
  display: none;

  @media only screen and (max-width: $mobile-width) {
    display: flex;
    justify-content: right;
  }
}

.notificationDropdown {
  padding-top: 15px;
}

.unscrollableContainer {
  overflow-y: hidden;
}

.mobileNav {
  display: flex;

  @media only screen and (max-width: $mobile-width) {
    display: none;
  }
}

.mobileMenu {
  display: flex;
  overflow: auto;
  width: 0px;
  top: 0;
  left: 100%;
  transition: all .3s ease;
  position: fixed;
  background-color: white;
  z-index: 1001;

  &__isOpen {
    width: 100vw;
    height: 100dvh;
    left: 0%;
  }

  &__body {
    width: 100%;
    height: 100dvh;
  }

  &__header {
    display: flex;
    align-items: center;
    height: $mobile-header-label-height;
    padding: 16px;
    padding-left: 0px;
    margin-bottom: 8px;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #DDD;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    display: flex;
  }

  &__divider {
    height: 1px;
    background-color: #a2a2a2;
    margin-bottom: 16px;
  }

  &__item {
    padding: 16px;
    font-size: 16px;
    font-weight: 400;
    color: $sl-dark-grey-mobile;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.isCurrent {
      color: $sl-ocean-blue;
    }
  }

  &__settingsItem {
    padding: 16px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $sl-dark-grey-mobile;
  }

  &__itemLabel {
    font-size: 16px;
    padding-left: 16px;
  }

  &__children {
    height: calc(100% - 176px);
    overflow: auto;
  }
}

.mobileSettingsNav {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 60px;
}

.mobileSubnavItem {
  font-size: 16px;
  color: #6F747E;
  padding: 6px 0px;
}

.logoIcon {
  color: $sl-ocean-blue;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.mobileWorkspace {
  padding: 24px 16px;
  padding-bottom: 36px;
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-top: 1px solid #DDD;
  background: $sl-tree-explorer-sidebar;

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  &__dropdown {
    padding-top: 14px;
    height: calc(100% - 42px);
    overflow: auto;
  }

  &__option {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
    
    &__name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 16px);
    }
  }

  &__new {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 8px 32px;
    border-radius: 4px;
    border: 1px solid $sl-ocean-blue;
    background-color: white;
    color: $sl-ocean-blue;
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
  }

  &__name {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
  }
}