.wrapper {
  .edit-button {
    transition: opacity 0.15s ease;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.emailWrapper {
  font-weight: 700;
  padding: 16px 0px;
}

.title {
  text-align-last: left;
}

.buttonsWrapper {
  padding: 24px 0px 0px 0px;
}
