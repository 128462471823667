@import '../../../styles/bootstrap-variables';
@import '../../../styles/colors';

.lease-badge {
  display: inline-block;
  vertical-align: middle;
  background: $sl-aliceblue;
  border-radius: 2em;
  margin: 0 $spacer;
  padding: 2px $grid-gutter-width * 0.5;
  text-transform: capitalize;
  white-space: nowrap;
}

.lease-badge--draft {
  background-color: $sl-mistyrose;
}

.lease-badge--reviewing {
  background-color: $sl-perfume;
}

.lease-badge--signing {
  background-color: $sl-teal;
}

.lease-badge--executed {
  background-color: $sl-snow-flurry;
}

.lease-badge--terminated {
  background-color: $sl-aliceblue;
}

.lease-badge--template {
  background-color: $sl-sand;
}

.lease-badge--preparing-to-sign {
  background-color: $sl-gold;
}
