@import '../lease-wizard-shared.scss';
@import '../../../styles/colors';

.lease-document-wizard-header {
  width: 100%;

  &__edit-button_margin {
    margin-right: 10px;
  }

  &.is-template {
    .lease-document-wizard-header-title {
      max-width: calc(100% - 436px);
    }
  }
}

.lease-document-wizard-subheader-info {
  width: 100%;
  margin-top: 3px;
  z-index: 0;
  background-color: $sl-azure;
  box-shadow: 0px -3px #ececec;

  &__edit-button_margin {
    margin-right: 10px;
  }
}

.lease-document-wizard-subheader-warning {
  width: 100%;
  margin-top: 3px;
  z-index: 0;
  background-color: $sl-light-pink;
  box-shadow: 0px -3px #ececec;

  &__edit-button_margin {
    margin-right: 10px;
  }
}

.lease-document-wizard-header__main {
  display: flex;
  min-height: 60px;
  border-bottom: 1px solid $sl-aliceblue;
  font-size: 1rem;
}
.lease-document-wizard-header__main-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.lease-document-wizard-header__main-close {
  background: transparent;
  border: none;
  border-right: 1px solid $sl-aliceblue;
  padding: 0 $lease-wizard-spacer;
  color: $sl-disabled-text;
  transition: color 0.3s ease;
  &:hover,
  &:focus {
    color: $sl-ocean-blue;
  }
}
.lease-document-wizard-header__main-close-icon {
  align-self: center;
  width: $font-size-base;
  height: $font-size-base;
}
.lease-document-wizard-header__main-content {
  align-self: center;
  margin: 0 $spacer;
}

.lease-document-wizard-header__main-actions {
  align-self: center;
  padding: 0 ($grid-gutter-width * 0.5);
}
.lease-document-wizard-header__main-action-cta {
  display: inline-block;
  margin-left: $grid-gutter-width * 0.5;
  margin-right: $grid-gutter-width * 0.5;
  text-align: right;
}

.lease-document-wizard-header-address__unit {
  font-weight: 500;
  &:after {
    content: '/';
    color: $sl-aliceblue;
    margin: 0 ($spacer * 0.5);
  }
}

.lease-document-wizard-header__actions {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  width: 100%;
}

.lease-document-wizard-header__actions-subheader {
  display: flex;
  justify-content: center;
  min-height: 40px;
  width: 100%;
}

.lease-document-header__last-updated {
  display: flex;
  list-style: none;
  padding: 5px;
  margin: 0 8px;
  white-space: nowrap;
  align-items: center;
  color: #a2a2a2;
  justify-self: flex-end;
}

.lease-document-header__create-template {
  margin: 4px;
  border-radius: 4px;

  > button {
    white-space: nowrap;
  }
}

.lease-document-header__edit-actions {
  display: flex;
  list-style: none;
  padding: 5px 15px;
  margin: 0;
  white-space: nowrap;

  .btn:disabled {
    opacity: 0.5;
  }
}

.lease-document-header__document-action-container {
  display: flex;
  justify-content: flex-end;
}

.lease-document-subheader__document-action-container {
  display: flex;
  justify-content: flex-end;
}

.lease-document-header__document-title-container {
  display: flex;
  justify-content: space-between;
}

.lease-document-subheader__document-title-container {
  display: flex;
  margin-left: $spacer;
  align-self: center;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

// @TODO: Change the layout for the header so that the entire thing uses flexbox, so we can align things properly
.lease-document-wizard-header-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1rem;
  height: 3rem;
}

.tooltip {
  pointer-events: none;

  &:hover {
    cursor: pointer;
  }
}

.lease-document-tooltip-container {
  display: flex;
  justify-content: space-between;
}

.send-for-signing-button {
  min-width: 148px;
}
