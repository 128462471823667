@import '../../styles/colors';
@import '../../styles/bootstrap-variables';

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999 !important;
}

.notification {
  padding: $spacer;
  margin: $spacer * 0.5;
  border-radius: $border-radius;
  text-align: center;
  border-left: 9px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  flex: auto;

  // The following padding is to mirror the dismiss button,
  // to keep the content actually centred
  padding-left: calc(#{$spacer * 3} + 26px);
}

.dismiss {
  padding: $spacer !important;
  margin: (-$spacer) 0 (-$spacer) $spacer;
  color: #737373 !important;
}

.info,
.warning {
  background-color: $sl-whitesmoke;
  color: $sl-almost-black;
  border-left-color: $sl-ocean-blue;
}

.success {
  background-color: $sl-honeydew;
  color: $sl-almost-black;
  border-left-color: $sl-greenish;
}

.error {
  background-color: $sl-dirty-pink;
  color: white;
  border-left-color: $sl-light-maroon;

  .dismiss {
    color: inherit !important;
  }

  a {
    color: white;
    font-weight: 500;

    &:hover {
      text-decoration: none;
    }
  }
}
