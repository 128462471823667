.wrapper {
  min-width: 800px;
  font-weight: 500;

  .title {
    border-bottom: 2px solid;
    padding: 0;
    margin: 0px 5px;
    font-size: 1.625rem;
  }

  .body_loading {
    min-height: 250px;
  }
}
