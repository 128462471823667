@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

$nav-width: 90px;

.edit-btn {
  background-color: transparent !important;
  border: none !important;

  svg {
    color: $sl-ocean-blue;
  }
}

.requestsTooltipContainer {
  svg {
    width: 20px;
  }
}

.settingsContainer {
  &__addOrgButton {
    text-align: end;
    padding-right: 0;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }

  &__title {
    display: inline-block;
    min-width: 10px;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
  }
}

.requestsWrapper {
  margin-top: $spacer;
  margin-left: $nav-width;
}

.uppercasedValue {
  text-transform: capitalize;
}

.deleteMembershipContainer {
  display: flex;
  gap: 20px;
  align-items: center;
  grid-auto-flow: column;
  max-width: 130px;
}

.handle-request-buttons {
  max-width: 100px;
  display: flex;
  justify-content: space-around;
}

.icon-delete {
  color: $ck-button-cancel;
  vertical-align: top;

  &:hover {
    cursor: pointer;
  }
}

.add-organization-button {
  margin-left: $spacer * 3;
}

.addOrganizationModal {
  padding: 20px 20px;
}

.handle-request-buttons {
  margin-right: $spacer;
}

.icon {
  &-approve {
    fill: $sl-greenish;
  }

  &-reject-delete {
    fill: $ck-button-cancel;
  }

  &-approve,
  &-reject-delete {
    opacity: 0.8;
  }

  &-disabled {
    fill: $sl-steel-grey;
  }

  &-approve:hover,
  &-reject-delete:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.searchInboxBox {
  min-width: 245px;
}

.filterDropdownContainer {
  display: flex;
  max-width: fit-content;

  &__filter {
    button {
      max-height: 35px;
    }
  }
}

.termsOfUseModalContent {
  width: 100%;
}

.termsOfUseModal {
  margin: 0;
  padding: 0;
  width: 600px;
}

.termOfUseOrgParsed {
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
}

.modalBody {
  > div {
    padding-bottom: 0;
  }
}

.membershipIdTooltip {
  font-size: 0.8em;
  color: $sl-ocean-blue;
  font-weight: lighter;
  margin-left: 0.5em;
}

.contentTable {
  height: calc(100% - 40px - $spacer);
  overflow: auto;
}