@import '../../../styles/colors';
@import '../../../components/lease-wizard/lease-wizard-shared';

$header-height: 50px;
$inspectorNavbox-height: 30px;

.lease-document-wizard-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
}

.lease-document-wizard-page__header-layout {
  background: #fff;
  position: relative;
  z-index: 3;
  box-shadow: 0 $wizard-header-shadow-size $wizard-header-shadow-size rgba($sl-dark-grey-blue, 0.1);
}

.lease-document-wizard-page__full-size-layout {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: start;
  flex-direction: column;
}

.lease-document-wizard-page__sidebar-layout {
  position: relative;
  z-index: 10;
  min-width: $wizard-sidebar-width;
  width: $wizard-sidebar-width;
  height: calc(100vh - #{$header-height + $inspectorNavbox-height});
  box-shadow: $wizard-sidebar-shadow-size 0 $wizard-sidebar-shadow-size rgba(#000, 0.05);
  display: flex;
  flex-direction: column;
}

.lease-document-wizard-page__sidebar-layout-hidden {
  display: none;
}

.lease-document-wizard-page__page-stack-layout {
  position: relative;
  overflow-y: auto;
  background: $sl-whitesmoke;
  padding: $wizard-page-vertical-margin $wizard-page-horizontal-margin;
  flex-grow: 1;
}

.lease-document-wizard-page__page-stack-layout-inner {
  max-width: $wizard-page-size;
  margin: auto;
}
