@import '../../../styles/colors';

.pendingInvitesModal {
  align-items: center;

  div {
    max-width: 500px;
  }
}

.pendingInvitesModalContainer {
  margin: 20px 20px;
}

.pendingInvitesModalHeader {
  font-weight: 500;
  margin-left: 10px;
  padding-bottom: 10px;
  text-align: left;
  border-bottom: 2px solid #18191b;
}

.pendingInvitesModalTable {
  margin-top: 10px;

  button {
    width: 100%;
  }

  thead {
    display: none;
  }
}
