@import '../../styles/bootstrap-variables.scss';
@import '../../styles/_colors.scss';

.paginationNav {
  padding: 0 80px 20px 0 !important;
}

.paginationButton {
  margin: 0 ($spacer * 0.5);

  &:not(:disabled) {
    color: $link-color;

    &:hover {
      color: $link-hover-color;
    }
  }

  &.isCurrent::after {
    position: static;
    content: '';
    width: 1em;
    height: 0;
    border-top: 2px solid currentColor;
    display: inline-block;
    margin-left: 1em;
    vertical-align: middle;
  }
}

table.styled {
  th {
    padding-top: 0;
    padding-bottom: 0;
  }

  .divider {
    height: 10px;
  }

  tbody td {
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  thead {
    tr > th {
      font-size: 0.875rem;
      font-weight: 500;

      padding-top: 0.625rem;
      padding-bottom: 0.625rem;

      * {
        vertical-align: middle;
        flex-flow: row;
        align-items: center;
      }
    }
  }

  tbody {
    td {
      background-color: $sl-aliceblue-tint;
    }
  }
}

tr.isClickable:hover > td {
  background-color: $sl-table-row-onhover !important;
  cursor: pointer;
}

tr.isClickable:active > td {
  background-color: $sl-disabled-button !important;
  cursor: pointer;
}

tr.isActionColumn > td:last-child {
  width: 50px;
}

tr.isStickyHeader {
  position: sticky;
  top: 0;
  background: white;
  // less than all menus
  z-index: 999;
}
