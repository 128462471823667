.wrapper {
  padding: 8px !important;
  text-align: left !important;

  &-disabled {
    opacity: 0.5 !important;
    pointer-events: none;
    cursor:unset;
  }

  &:hover {
    cursor:move !important;

    .icon {
      display: none;
    }

    .drag-handle {
      display: inline;
    }
  }
}

.icon {
  width: 22px;
  height: auto;
  margin-right: 8px;
}

.drag-handle {
  width:22px;
  display: none;
  margin-right: 8px;
}

