@use "sass:math";

@import '../../../styles/bootstrap-variables';
@import '../../../styles/colors';

.lease-document-wizard-cta-stack--vertical {
  padding: $spacer;
}
.lease-document-wizard-cta-stack--horizontal {
  margin: 0 !important;
}

.lease-document-wizard-cta-stack__button {
  margin-left: $spacer;
  &.btn-outline-primary,
  &.btn-outline-secondary {
    &:not(:hover) {
      background: #fff;
    }
  }
}
.lease-document-wizard-cta-stack__button--disabled {
  &:hover {
    cursor: default;
  }
}
.lease-document-wizard-cta-stack__button--vertical {
  width: calc(100% - #{$spacer});
}

.lease-document-wizard-modal-custom + .fade {
  z-index: 1110 !important;
}

.lease-document-wizard-modal-custom {
  > div {
    max-width: 460px;
  }
}

.lease-document-wizard-modal-small-text {
  font-size: 12px;
}


.lease-document-wizard-modal-button-section {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 0 !important;
  > button {
    margin-left: 0;
  }
  > div {
    max-width: 100px;
    margin-left: 1em;
  }
}
