@import '../../styles/colors';
@import '../../styles/bootstrap-variables';

//
// Card styles

.card {
  //background: linear-gradient(145deg, white 0 50%, rgba(white, 0.92) 75%, rgba(white, 0.88)),
  //  url('../../shared/cube-texture-blue.svg') center/60px, white;
  background-color: $sl-card-background-blue;
  border-radius: $border-radius;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: $spacer * 2;
  min-height: 395px;
  overflow: auto;
  padding: $spacer * 2.1875 $spacer * 1.875;
  position: relative;

  &:hover,
  &:focus {
    .cardIcon {
      display: block;
    }
  }
}

.activityCard {
  //background: linear-gradient(145deg, white 0 50%, rgba(white, 0.92) 75%, rgba(white, 0.88)),
  //  url('../../shared/cube-texture-blue.svg') center/60px, white;
  background-color: $sl-card-background-blue;
  border-radius: $border-radius;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: $spacer * 2;
  min-height: 385px;
  overflow: auto;
  padding: $spacer * 2.1875 $spacer * 1.875;
  position: relative;

  &:hover,
  &:focus {
    .cardIcon {
      display: block;
    }
  }
}

.cardIcon {
  display: none;
  position: absolute;
  top: $spacer;
  right: $spacer;
  cursor: pointer;

  &:hover,
  &:focus {
    display: block;
  }

  a {
    padding: $spacer;
  }
}

.cardBlue {
  //background: linear-gradient(145deg, $sl-whitesmoke 0 50%, rgba($sl-whitesmoke, 0.92) 75%, rgba($sl-whitesmoke, 0.88)),
  //  url('../../shared/cube-texture-blue.svg') center/60px, $sl-whitesmoke;
  background-color: $sl-card-background-blue;
}

.cardFullHeight {
  height: calc(100vh - ($spacer * 13));
  margin: 0px -15px;
}

.activitiesCard {
  padding: $spacer $spacer !important;
}

.cardTitle {
  font-size: $font-size-nominal-default;
  margin-bottom: $spacer * 1.5;
}

.descriptionTitle {
  font-size: $font-size-nominal-default;
  font-weight: 500;
  margin-bottom: 0;
  color: #737373;
}

.upgradeButtonContainer {
  text-align: center;
  margin-top: $spacer * 1.5;
  overflow: hidden;
  min-height: auto;

  button {
    overflow: visible;
    margin: $spacer/4;
  }
}

//
// Activity Card styles

.activity {
  border-bottom: 1px solid $sl-border-grey;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  .activityDate {
    color: $sl-disabled-text;
    margin-bottom: $spacer * 0.5;
  }
}

.activityTitle {
  color: $sl-ocean-blue;
  margin-bottom: $spacer * 0.5;
}

.profileHeader {
  display: flex;
  align-items: center;
  margin-bottom: $spacer * 2;
  max-height: 42px;

  h4 {
    margin-bottom: 0;
  }
}

.subscriptionHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: $spacer/2;
  margin-bottom: $spacer;
  height: 42px;

  h4 {
    margin-bottom: 0;
    margin-left: 0;
  }
}

.toggleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer;
}

.customToggleStyles {
  transform: scale(0.7);
}

.toggleTextContainer {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.cardRow {
  align-items: center;
  background: none;
  border: none;
  border-bottom: 1px solid $sl-border-grey;
  display: flex;
  justify-content: space-between;
  padding: $spacer * 0.5 0;
  text-align: left;
  width: 100%;
  p {
    margin-bottom: 0;
  }
  svg {
    color: $sl-dark-grey;
  }
}

.cardRow_noBorder {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  padding: $spacer * 0.5 0;
  text-align: left;
  width: 100%;
  p {
    margin-bottom: 0;
  }
  svg {
    color: $sl-dark-grey;
  }
}

.editableRow {
  cursor: pointer;
}

.removeAccount {
  text-align: right;
  padding-top: $spacer;
  svg {
    margin-right: $spacer * 0.5;
  }
  button {
    text-decoration: underline;
    padding-left: 0;
    padding-right: 0;
  }
}
.cardContent {
  overflow-x: hidden;
  overflow-y: auto;
}

.cardSidebar {
  background-color: white !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.passwordModal__body {
  margin: 2em 0;
}

.passwordModal__actions {
  display: flex;
  justify-content: right;
  gap: 2em;
}
