@import '../../../styles/bootstrap-variables';
@import '../../../styles/colors';

$lease-modal-padding: $spacer * 4;
.lease-document-wizard-modal__title {
  font-weight: 500;
}

@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
  .lease-document-wizard-modal .modal-sm {
    max-width: $modal-md;
  }
  .lease-document-wizard-modal__header.modal-header {
    padding: $lease-modal-padding;
    padding-bottom: $spacer * 0.5;
  }

  .lease-document-wizard-modal__header.modal-header.template_modal {
    padding: 2rem;
    padding-bottom: 0rem;
    align-self: center;
  }

  .lease-document-wizard-modal__title.upload-modal-title {
    font-size: large !important;
  }

  .lease-document-wizard-modal__body.modal-body {
    width: 100%;
    padding: $spacer $lease-modal-padding;
    // in case there's no footer
    &:last-child {
      padding-bottom: $spacer * 3;
    }

    .modal-disclaimer {
      margin-top: -1.4em;
      margin-bottom: 1.5em;
      font-size: small;
      color: $sl-dark-grey;
    }
  }

  .lease-document-wizard-modal__body.modal-body.template_modal {
    padding: 1rem 2rem;
    align-self: center;
  }

  .lease-document-wizard-modal__footer.modal-footer {
    padding: $lease-modal-padding;
    padding-top: $spacer * 0.5;
  }

  .lease-document-wizard-modal__footer.modal-footer.template_modal {
    padding: 0 1rem 2rem 0rem;
    align-self: center;
  }

  .lease-document-wizard-modal__body.modal-body.template_modal {
    padding: 1rem 2rem;
    align-self: center;
  }

  .lease-document-wizard-modal__download-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lease-document-wizard-modal__download-link {
    border: none;
    background: transparent;
    padding: ($spacer * 0.5) ($grid-gutter-width * 0.5);
    color: $sl-ocean-blue;
    transition: box-shadow 0.2s ease-in-out, background 0.2s ease-in-out;
    font-weight: 500;

    &:hover {
      background: $sl-whitesmoke;
      box-shadow: inset 0 0 0 0.25rem #fff;
    }
  }

  .lease-document-wizard-modal__download-icon {
    width: 1.5rem;
    height: 1.25rem;
    margin: 0 0.25rem;
    align-self: center;
    color: $sl-ocean-blue;
  }

  .lease-document-wizard-modal .modal-sm {
    max-width: 300px;
  }
}
