@import '../../styles/colors';

.root {
  width: 1rem;

  // Make the icon slightly bolder
  path {
    stroke: currentColor;
    stroke-width: 1px;
  }
}

.tooltip {
  z-index: 10000 !important;
}

.primaryTooltip_top > :global(.arrow):before {
  border-top-color: $sl-aliceblue !important;
}

.primaryTooltip_left > :global(.arrow):before {
  border-left-color: $sl-aliceblue !important;
}

.primaryTooltip_right > :global(.arrow):before {
  border-right-color: $sl-aliceblue !important;
}

.primaryTooltip_bottom > :global(.arrow):before {
  border-bottom-color: $sl-aliceblue !important;
}

.primaryTooltip.primaryTooltip_top-start {
  padding: 0.4rem 0 !important;
  top: 6px !important;

  :global(.arrow) {
    bottom: 0 !important;

    &::before {
      top: 0 !important;
      border-width: 0.4rem !important;
      border-top-color: $sl-aliceblue !important;
    }
  }
}

.primaryTooltip > :global(.tooltip-inner) {
  background-color: $sl-aliceblue;
  color: $sl-almost-black;
  max-width: 320px;
}

//confirmation tooltip

.confirmationTooltip_top > :global(.arrow):before {
  border-top-color: $sl-greenish !important;
}

.confirmationTooltip_left > :global(.arrow):before {
  border-left-color: $sl-confirmation-green !important;
}

.confirmationTooltip_right > :global(.arrow):before {
  border-right-color: $sl-confirmation-green !important;
}

.confirmationTooltip_bottom > :global(.arrow):before {
  border-bottom-color: $sl-confirmation-green !important;
}

.confirmationTooltip.confirmationTooltip_top-start {
  padding: 0.4rem 0 !important;
  top: 6px !important;

  :global(.arrow) {
    bottom: 0 !important;

    &::before {
      top: 0 !important;
      border-width: 0.4rem !important;
      border-top-color: $sl-confirmation-green !important;
    }
  }
}

.confirmationTooltip > :global(.tooltip-inner) {
  background-color: $sl-confirmation-green;
  color: $sl-white;
  max-width: 320px;
}

.boxTooltip {
  --rt-opacity: 1 !important;
}

.errorTooltip_top > :global(.arrow):before {
  border-top-color: $sl-light-red !important;
}

.errorTooltip_left > :global(.arrow):before {
  border-left-color: $sl-light-red !important;
}

.errorTooltip_right > :global(.arrow):before {
  border-right-color: $sl-light-red !important;
}

.errorTooltip_bottom > :global(.arrow):before {
  border-bottom-color: $sl-light-red !important;
}

.errorTooltip.errorTooltip_top-start {
  padding: 0.4rem 0 !important;
  top: 6px !important;

  :global(.arrow) {
    bottom: 0 !important;

    &::before {
      top: 0 !important;
      border-width: 0.4rem !important;
      border-top-color: $sl-light-red !important;
    }
  }
}

.errorTooltip > :global(.tooltip-inner) {
  background-color: $sl-light-red;
  color: $sl-white;
  max-width: 320px;
}
