@import '../../../../../styles/_colors';

.wrapper {
  padding: 55px 15px 15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 50px);
  // width: 101%;

  &-insertion {
    padding: 15px 15px;
  }

  &-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.signers-container {
  overflow-y: auto;
  padding: 0 3px;
  max-height: calc(100vh - 365px);
  border-bottom: 1px solid #9a9898;
  padding-bottom: 10px;

  &-insertion {
    max-height: calc(100vh - 535px);
  }
}

.signers-title {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: $sl-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.insert-fields {
  border-bottom: 1px solid $sl-medium-grey;
  padding-bottom: 30px;
}
