.wrapper {
  width:27px;
  height: auto;
  margin-left: 1rem;
  content: "";
  font-size: 1em;
  :hover {
    cursor: pointer;
  }
}
