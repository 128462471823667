@import '../../styles/bootstrap-variables';

.full-page-element {
  background-color: white;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: $zindex-sticky;
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    border: none;
    height: 100%;
    width: 100%;
  }
}

.full-page-element--hidden {
  display: none;
}
