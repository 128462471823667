@import '../../../styles/colors';
@import '../../../styles/bootstrap-variables';

//
// Card styles

.emailReplyTitle {
  color: $sl-ocean-blue;
  font-weight: 500;
}

.emailReplyHeader {
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.emailReplyBody {
  margin-top: $spacer;
  max-height: 300px;
  overflow: auto;
}

.emailReplyAttachmentsContainer {
  margin-top: $spacer;
  padding-top: $spacer;
  border-top: 1px solid $sl-border-grey;
}

.showNestedMessagesButton {
  background-image: url('../../../shared/icons/ellipsis.png');
  background-color: $sl-light-grey;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border-radius: 5px;
  height: 10px;
  width: 24px;

  &:hover {
    background-color: $sl-border-grey;
    cursor: pointer;
  }
}
