@use "sass:math";

@import '~bootstrap/scss/functions';
@import './functions';
@import 'colors';

$enable-validation-icons: false;
/*
Place any overrides for bootstrap variables in this file, BETWEEN the @imports.
We can then import this file elsewhere in the project to access all of
bootstrap's variables with our own overrides in place.
*/

$font-size-nominal-default: 16px;
$font-size-base-target: 14px;

// --- Colors ------------------------------------------------------------------

$blue: $sl-ocean-blue;
$red: $sl-light-red;
$orange: $sl-blood-orange;
$green: $sl-greenish;

$primary: $sl-ocean-blue;
$secondary: $sl-blood-orange;
$success: $sl-greenish;
$danger: $sl-light-red;
$warning: $sl-blood-orange;
$info: #a2a2a2;
$light: #f6f6f6;
$dark: $sl-almost-black;

$body-color: $sl-almost-black;
$table-accent-bg: $sl-table-stripe;
$modal-backdrop-bg: $sl-dark-grey-blue;
$modal-backdrop-opacity: 0.56;

$modal-md: 625px;

// --- Layout

$border-radius: math.div(1rem * 2px, $font-size-nominal-default);
$table-cell-padding: 1rem;

// --- Sizes -------------------------------------------------------------------

$spacer: 1rem; // Same as default, but we need it here as a variable

// These are the same as the non-zero default spacers
$sizes: (
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
);

// --- Typography --------------------------------------------------------------

$font-family-sans-serif: Arial, sans-serif;

$font-size-base: math.div(1rem * $font-size-base-target, $font-size-nominal-default);
$font-size-lg: math.div(1rem * 18px, $font-size-nominal-default);

$h1-font-size: math.div(1rem * 38px, $font-size-nominal-default);
$h2-font-size: math.div(1rem * 26px, $font-size-nominal-default);
$h3-font-size: math.div(1rem * 16px, $font-size-nominal-default);
$h4-font-size: math.div(1rem * 15px, $font-size-nominal-default);
$h5-font-size: math.div(1rem * 15px, $font-size-nominal-default);
$h6-font-size: math.div(1rem * 14px, $font-size-nominal-default);

$headings-font-weight: 700;
$headings-margin-bottom: math.div(1rem * 32px, $font-size-nominal-default);

$text-muted: #737373;

$lease-wizard-section-title: $font-size-lg;
$lease-wizard-required-label: $font-size-nominal-default * 0.75;

// --- Buttons & Forms ---------------------------------------------------------

$btn-padding-y: 8px;
$btn-padding-x: 16px;
$btn-font-weight: 500;
$btn-focus-box-shadow: none;
$btn-disabled-opacity: 1;

$label-margin-bottom: 0;

$input-padding-x: 0.5rem;
$input-color: $sl-almost-black;
$input-border-color: #a2a2a2;
$input-focus-box-shadow: none;
$input-focus-border-color: $blue;

$form-text-margin-top: 0;

/*$input-height: 5px;*/
// Increase the size of the feedback icons slightly (3/4 height instead of 1/2)
$input-height-inner-half: calc(#{1.5 * 0.75em} + 0.375rem);
$input-height-inner-quarter: calc(#{1.5 * 0.125em} + #{0.375rem / 2});

$form-feedback-font-size: math.div(1rem * 13px, $font-size-nominal-default);
$form-feedback-margin-top: 0;
$form-feedback-icon-valid: url('../../../../src/shared/icons/form-icon-valid.svg');
$form-feedback-icon-invalid: url('../../../../src/shared/icons/form-icon-invalid.svg');

// --- Progress bar ------------------------------------------------------------

$progress-height: 0.75rem;

// --- Checkbox & Radio fields  ------------------------------------------------
$custom-control-gutter: 0.5rem !default;
$custom-control-spacer-x: 1rem !default;

$custom-control-indicator-size: 1.25rem;

$custom-control-indicator-bg-size: 50% 50% !default;

$custom-control-indicator-focus-border-color: #000;
$custom-control-indicator-checked-color: $green;
$custom-control-indicator-checked-bg: #fff;
$custom-control-indicator-checked-disabled-bg: rgba($green, 0.5);
$custom-control-indicator-checked-border-color: $green;
$custom-control-indicator-active-color: $green;
$custom-control-indicator-active-bg: lighten($green, 35%);
// inlined version of shared/checkmark-icon.svg
$custom-checkbox-indicator-icon-checked: svg-data-uri(
  '<svg width="16" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M1 6.714L5.2 11 15 1" stroke="#fff" stroke-width="1.8" fill="none" stroke-linecap="round"/></svg>'
);
$custom-checkbox-indicator-icon-indeterminate: svg-data-uri(
  '<svg width="16" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M2,6.5H14" stroke="#fff" stroke-width="1.8" fill="none" stroke-linecap="round"/></svg>'
);
// inlined version of shared/chevron-down.svg
$custom-select-indicator: svg-data-uri(
  '<svg viewBox="0 0 9.5 6" version="1.1" xmlns="http://www.w3.org/2000/svg"><polyline stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" points="8.75,0.75 4.75,4.75 0.75,0.75" /></svg>'
);

// --- Dropdown ----------------------------------------------------------------
$dropdown-item-padding-x: 1rem;
$dropdown-link-hover-bg: mix(white, $sl-ocean-blue, 91%);
$dropdown-link-active-bg: mix(white, $sl-ocean-blue, 91%);
$dropdown-link-active-color: inherit;

// --- Tooltip ----------------------------------------------------------------
$tooltip-opacity: 1;

// --- Lease Wizard ------------------------------------------------------------
$lease-wizard-document-width: math.div(1rem * 770px, $font-size-nominal-default);
$lease-wizard-field-width: 200px;

$mobile-width: 768px;

@import '~bootstrap/scss/variables';
