@import '../../../styles/colors';
@import '../../../styles/bootstrap-variables';

.requestReviewDropdown {
  text-align: -webkit-center;
}

.dropdownTitle {
  display: block;
  font-weight: 500;
}

.requestInternalApprovalModalContainer {
  margin: 0;
  padding: 0;
  background-color: red !important;
}

.createDocumentInputForm {
  margin-top: -20px;
}

.editorFooterActionsContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  width: 100%;
}

.editorFooterActionsContainerWithoutLock {
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
  width: 100%;
}

.editorFooterActionButtons {
  display: flex;
  padding-top: 4px;
  justify-content: end;
}

.headerFooterLockContainer {
  display: flex;
  gap: 10px;
}

.setApprovalRequirementToggle {
  display: flex;
  margin-top: $spacer;

  &__label {
    margin-right: $spacer;
    padding-top: $spacer/3;
  }

  &__tooltip {
    display: inline-block;
    transform: translateY(-10px);
  }
}

.teamMembersDropdown {
  width: 100%;
}
.handleApprovalRequestButtonsGroup {
  button {
    min-width: 100px;
    margin-right: $spacer/2;
  }
}

.teamMembersDropdown__container {
  width: 300px;
}

.manageDealTeamLink {
  color: #006c90;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    color: #003344;
    text-decoration: underline;
  }
}

.headerFooterModalContainer {
  .modal-dialog {
    max-width: fit-content;
  }
}

.modal-backdrop {
  // higher than table header
  z-index: 1100 !important;
}

.modal {
  // higher than table header
  z-index: 1110 !important;
}

.modalHeaderContainer {
  display: flex;
  border-bottom: 1px solid $sl-ocean-blue;
}

.modalHeaderTab,
.modalFooterTab {
  color: $sl-disabled-text;
  font-weight: 500;
  font-size: 1rem;
  padding: $spacer/2 $spacer;
  padding-top: 0;
  min-width: 150px;
  text-align: center;
  cursor: default;

  &.activeTab {
    color: $sl-ocean-blue;
    border-bottom: 2px solid $sl-ocean-blue;
  }
}

.footerEditor,
.headerEditor {
  display: none;
  &.activeTab {
    display: block;
  }
}
