.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #f2f2f2;
  padding: 5vmax;
}

.icon {
  height: 48px;
  width: 48px;
  margin: 20px auto;
}

.title,
.text {
  text-align: center;

  .title {
    font-size: 2rem;
  }
}
