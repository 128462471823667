@import '../../../../../styles/bootstrap-variables';

.dealModalInputWrapper {
  margin-top: $spacer;

  &__input {
    display: block;
    width: 100%;
    min-height: 90px;
    padding: 0.375rem 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    border: 1px solid #a2a2a2;
    border-radius: 0.125rem;
    resize: none;

    &:focus {
      border-color: #006c90;
      outline: 0;
      box-shadow: none;

      &::placeholder {
        visibility: hidden;
      }
    }
  }
}
