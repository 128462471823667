@import 'src/styles/_colors.scss';
@import 'src/styles/_bootstrap-variables.scss';

$header-height: 50px;
$inspectorNavbox-height: 30px;

.wrapper {
  padding: 0.85rem 1.25rem;
  padding-bottom: 2rem;

  &-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.templates-container {
  padding: 16px 0;
  height: calc(100vh - #{$header-height + $inspectorNavbox-height * 5});
  overflow: auto;
}

.signers-title {
  margin-bottom: 5px;
  font-size: 16px;
}

.title {
  padding: 0;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 400;
  color: $sl-grey;
}

.buttons {
  margin: 10px 0 0;
  justify-content: right;

  &-add:disabled {
    opacity: 0.5 !important;
  }
}

.searchInput {
  width: 100%;
}

.placeholderItemContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid $sl-border-grey;
  padding: 0.5rem;
  justify-content: space-between;
  border-style: none solid;
  border-width: 4px;
  border-color: transparent;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid $sl-border-grey;
  background-color: white;
  color: $sl-dark-deep-grey;
  margin-bottom: 10px;
  border-radius: 6px;

  &:hover {
    background-color: $sl-light-grey;
  }

  &__active {
    background-color: $sl-light-grey;
    border-left: 4px solid $sl-blue-button-onhover;
  }

  &__title {
    font-size: 15px;
    align-items: center;
    padding-bottom: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $sl-grey;
    font-weight: 600;
  }

  &__requirement {
    font-size: 12px;
    font-weight: 400;
    color: $sl-grey;
    margin-top: $spacer * 0.125;
  }
}

.noPlaceholders {
  border-radius: 8px;
  padding: 1rem 2rem;
  text-align: center;
  background-color: $sl-aliceblue-tint;
}
