// This file is included on every page, so put global styles in here.

@import 'bootstrap';
@import 'bootstrap-overrides';
@import 'datepicker-overrides';
@import 'uppy-overrides';
@import 'components/flyout-nav';

$legal-document-ol-indent: 2.5rem;

@media only screen and (max-width: $mobile-width) {
  input, button, label, a, p, select, textarea {
    font-size: 16px !important;
  }
}

h1,
.h1 {
  font-weight: 300;
}

// Otherwise h* tags within documents are incorrectly not bolded in some cases
.ck-content h1,
.ck-content h2,
.ck-content h3,
.ck-content h4,
.ck-content h5,
.ck-content h6,
.ck-content .h1,
.ck-content .h2,
.ck-content .h3,
.ck-content .h4,
.ck-content .h5,
.ck-content .h6 {
  font-weight: revert;
}

// The .body-text class is intended to be applied to any long-form text blocks
.body-text {
  a,
  .btn-link {
    font-weight: 500;
    text-decoration: underline;
  }

  &.legal-document {
    ol {
      counter-reset: item;
      padding-inline-start: $legal-document-ol-indent;

      li {
        display: block;
        position: relative;
        padding-top: 0.5rem;

        &:before {
          content: counters(item, '.') '.';
          counter-increment: item;
          font-weight: normal;
          position: absolute;
          margin-right: 100%;
          right: 0px;
          width: $legal-document-ol-indent; /* width + right must equal padding-inline-start for container ol */
        }

        p {
          margin-top: 0.5rem;
          margin-bottom: 0;
        }

        & > ol {
          padding-left: 0;

          ol {
            padding-left: $legal-document-ol-indent;

            li {
              &:before {
                content: '(' counter(item, lower-alpha) ')';
              }

              ol {
                li {
                  &:before {
                    content: '(' counter(item, lower-roman) ')';
                    font-weight: normal;
                  }
                }
              }
            }
          }
        }

        /* Section titles */
        h3 {
          display: inline;
          font-size: inherit;
          font-weight: bold;
        }

        /* Subsection titles */
        h4 {
          display: inline;
          font-size: inherit;
          font-weight: inherit;
          text-decoration: underline;
        }

        div.mailing-address {
          margin-top: 0.5rem;
          padding-left: 4rem;
          font-weight: normal;
        }
      }
    }

    span.legal-term {
      font-weight: bold;
    }

    .important {
      font-weight: bold;
    }

    .extra-important {
      text-transform: uppercase;
    }

    .emphasis {
      font-style: italic;
    }
  }
}

.accept-terms {
  z-index: 0 !important;
}
.mobile {
  display: none !important;

  &-flex {
    display: none !important;
  }

  @media only screen and (max-width: $mobile-width) {
    display: block !important;

    &-flex {
      display: flex !important;
    }
  }
}

.non-mobile {
  display: block !important;

  &-flex {
    display: flex !important;
  }

  @media only screen and (max-width: $mobile-width) {
    display: none !important;

    &-flex {
      display: none !important;
    }
  }
}

.mobileHeaderBtn {
  height: 48px !important;
  width: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.mobileSearch {
  height: 48px !important;
  font-size: 14px;
  padding: 12px 16px 12px 42px !important;

  &__icon {
    left: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__container {
    width: 100%;
    margin-right: 16px;
  }
}

.filterBtn {
  background-color: white;
  border: 1px solid #DDD;
  border-radius: 4px;
  padding: 11px !important;
  color: #6F747E;
}

.dealItem__status {
  padding: 6px 10px !important;
  border: 0 !important;
  border-radius: 100px !important;
  background: rgba(0, 108, 144, 0.16) !important;
  font-size: 12px !important;
  color: $sl-dark-grey-mobile !important;
}