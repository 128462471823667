@import '../bootstrap-variables.scss';

.flyout-nav {
  .nav-link {
    padding: 25px 25px 25px 35px;

    &:hover,
    &.active {
      background-color: #d1e8f0; // seems to be a one-off, only in the sub-nav
      font-weight: 700;
      color: $sl-ocean-blue;
    }
  }
}
