@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

.dealDetails {
  dl {
    display: flex;
    align-items: baseline;

    > div + div {
      margin-left: $spacer;
    }

    dt {
      color: #737373;
    }
  }

  ul {
    margin-top: $spacer;
    list-style-type: none;
    column-count: 2;
    column-gap: $spacer;
    padding: 0;
  }
}

.contentTable {
  height: calc(100% - 40px - $spacer);
  overflow: auto;

  @media only screen and (max-width: $mobile-width) {
    height: calc(100% - 128px);
  }
}

.spacedRows {
  :global(.row) + :global(.row) {
    margin-top: $spacer;
  }
}

.dropdownBody {
  border-top: 1px solid $sl-border-grey;
  overflow: auto;
  max-height: 300px;
}

.dropdownItem {
  white-space: nowrap;
  max-width: 400px;

  :global(.custom-control) {
    padding-left: 0;
  }
  :global(.form-group) {
    margin-bottom: 0;
  }

  &.highlightable {
    &:hover,
    &:focus-within {
      background-color: $sl-table-stripe;
    }
  }

  label {
    width: 100%;
    padding: $spacer * 0.75;
    padding-left: $spacer * 0.75 + 1.75rem;

    > span {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    }

    &::before,
    &::after {
      top: 50%;
      transform: translateY(-50%);
      left: $spacer * 0.75;
    }
  }

  &:not(:first-child) {
    border-top: 1px solid $sl-aliceblue;
  }
}

.bankAccount {
  margin-bottom: $spacer * 1.5;
  .accountLabel {
    position: relative;
    padding-bottom: $spacer * 1.5;
    margin-top: $spacer * -1;
    margin-bottom: $spacer * 1.5;
    &::after {
      content: '';
      border-bottom: 1px solid $sl-deep-sea-blue;
      left: 50%;
      position: absolute;
      bottom: 0;
      transform: translateX(-50%);
      width: 80px;
    }
  }

  dl {
    margin-bottom: 0;

    dt {
      color: #737373;
    }
  }
}

.bankAccountModal {
  :global(.modal-content) {
    padding: $spacer * 2;
  }
}

.adminLinks {
  & > :global(.btn-link) {
    border-width: 0;

    &:not(:last-child) {
      &::after {
        content: '';
        border-right: 1px solid $sl-light-blue;
        font-size: 0.75rem;
        position: relative;
        left: calc(#{$spacer * 1.25} + 1px);
      }
    }
  }
}

.headerCta {
  button {
    min-width: 10rem;
  }
}

.templateModal {
  padding: 20px 25px;
}

.templateModalHeader {
  display: block;
  font-size: large;
  margin: 5px -5px;
}

.createTemplateModalContainer {
  max-width: 400px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.buttonsContainer {
  margin-top: -15px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.templateStatus {
  text-transform: capitalize;
}

.addTemplateActionsContainer {
  display: flex;
  justify-content: right;
}

.searchInboxBox {
  min-width: 210px;
}

.filterDropdownContainer {
  display: flex;
  width: 100%;
  max-width: fit-content;
}

.restrictedEditingField {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px 0;

  &__label {
    color: #737373;
    font-weight: 700;
    margin-right: $spacer;
    padding-top: $spacer/3;
  }

  &__tooltip {
    display: inline-block;
    transform: translateY(-10px);
  }
}

.dottedDropdown {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: unset;
  fill: $sl-steel-grey;
  stroke: $sl-steel-grey;
  color: $sl-whitesmoke;

  &:hover {
    color: $sl-steel-grey;
    fill: $sl-whitesmoke;
  }

  &:active {
    color: $sl-whitesmoke;
    stroke: $sl-ocean-blue;
    fill: $sl-ocean-blue;
  }

  &[aria-expanded='true'] {
    color: $sl-whitesmoke;
    stroke: $sl-ocean-blue;
    fill: $sl-ocean-blue;
  }
}

.headerTitleTableColumn {
  min-width: 400px;
}

.actionsTableColumn {
  width: 100%;
  text-align: right;
}

.templatesTooltipContainer {
  svg {
    width: 20px;
  }
}
