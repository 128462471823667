@import '../../../../styles/bootstrap-variables';
@import '../../../../styles/colors';

.documentRevisionTopToggle {
  min-width: 200px;
  display: flex;
  margin-left: 20px;
  label {
    border-radius: unset !important;
  }
}

.documentRevisionSectionButton {
  background-color: transparent !important;
  border-color: transparent !important;
  color: $sl-steel-grey !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 50%;
  border: none !important;
  padding-bottom: 11px !important;
  border-radius: 0 !important;
  font-weight: 400 !important;
  font-size: 16px !important;

  input {
    display: none;
  }
}

.documentRevisionSectionButtonActive {
  background-color: transparent !important;
  border-color: transparent !important;
  color: $sl-ocean-blue !important;
  width: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none !important;
  border-bottom: solid 5px $sl-ocean-blue !important;
  border-radius: 0 !important;
  font-size: 16px !important;

  input {
    margin-bottom: 3px;
    display: none;
  }
}

.switcherWrapper {
  display: flex;
  margin: 0 $spacer * 2;
  font-weight: 500;
  align-items: center;

  span {
    padding: 0 $spacer/2;
  }

  &__editorMode {
    color: $sl-disabled-text;
  }

  .activeEditMode {
    color: $sl-ocean-blue;
  }
}
