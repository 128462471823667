@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

$nav-width: 90px;
$nav-height: 373px;
$sub-nav-width-collapsed: 20px;
$sub-nav-width-expanded: 190px;
$nav-box-shadow: 2px 0 4px $sl-shadow;
$button-width: 205px;

.root {
  display: flex;
}

.manageParticipantsWrapper__show,
.manageParticipantsWrapper__collapsed {
  display: block;
  position: fixed;
  // z-index: 1;
  right: 0;
  width: 75vw;
  max-width: 900px;
  height: 100vh;
  transition: visibility 0.3s, opacity 0.3s, z-index 0 linear;
  overflow-y: auto;
  overflow-x: hidden;
}

.contentTable {
  height: calc(100% - 40px - $spacer);
  overflow: auto;
}

.manageParticipantsWrapper__collapsed {
  width: 0;
  transition: 3s ease;
}

.manageParticipantsWrapper__show,
.manageParticipantsWrapper__collapsed {
  // higher than table header
  z-index: 1002;
  background-color: white;
  box-shadow: 0 8px 8px $sl-shadow;
  transition: 0.3s ease;

  .manageParticipants {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease;

    &__header {
      position: fixed;
      background-color: $sl-whitesmoke;
      padding: 0.8rem 16px;
      width: 75vw;
      border-bottom: 1px solid $sl-ocean-blue;
      max-width: 900px;
      z-index: 1;

      @media screen and (max-width: 768px) {
        border-bottom: none;
      }
    }
  }
}

.manageParticipants {
  &__header {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 0.8rem 16px;
    box-shadow: 0 2px 4px $sl-shadow;
    width: 50vw;
  }

  &__tabsContainer {
    display: flex;
    text-align: center;
  }

  &__tab {
    font-size: 1rem;
    font-weight: 500;
    padding-top: $spacer;
    padding-bottom: $spacer;
    color: $sl-gray-label;
    // border-bottom: 1px solid $sl-gray-label;

    &--active {
      font-weight: 500;
      color: $sl-ocean-blue;
      border-bottom: 3px solid $sl-ocean-blue;
    }

    &:hover {
      cursor: default;
    }
  }

  &__content {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 75px;
  }

  &__close,
  &__expand {
    color: $sl-ocean-blue;
    align-self: center;
    text-align: center;
    font-size: 1rem;

    * {
      margin-left: $spacer/2;
    }

    &:hover {
      cursor: pointer;
      color: $sl-ocean-blue;
    }
  }

  &__close {
    svg {
      margin-bottom: 2px;
    }
  }

  &__expand {
    margin-right: $spacer * 1.3;
  }

  &__dealTeam {
    background-color: white;
    width: 100%;
    max-width: -webkit-fill-available;
    visibility: collapse;
    max-height: 0;
    overflow: hidden;

    nav {
      width: 100%;
      padding-bottom: 0 !important;
    }

    &--show {
      visibility: visible;
      overflow: visible;
      max-height: unset;
      height: 100%;
    }
  }

  &__counterparty {
    background-color: white;
    visibility: collapse;
    max-height: 0;
    overflow: hidden;

    &--show {
      visibility: visible;
      overflow: auto;
      max-height: unset;
      height: 100%;
    }
  }
}

.manageDealMembersSubpanel {
  color: $sl-ocean-blue;
  white-space: nowrap;

  &__link {
    &:hover {
      cursor: pointer;
      color: $sl-ocean-blue;
      text-decoration: underline;
    }
  }
}

.teamMembersListContainer {
  width: 100%;
  height: 100%;
  margin-top: $spacer;
  overflow-y: auto;
  overflow-x: hidden;
}

.participantsButtonContainer {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 1000;

  @media screen and (max-width: $mobile-width) {
    margin-left: -15px;
    margin-right: -15px;

    .handleParticipantsButton {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}

.handleParticipantsButton {
  width: $button-width;
}

.counterpartyPrimaryContact {
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
}

.counterpartyParticipantName {
  display: inline-block;
  min-width: 145px;
}
.closeParticipants {
  span {
    &:first-child {
      display: none;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .inviteBtn {
    width: 100%;
    padding: 12px 32px !important;
  }

  .closeParticipants {
    padding: 16px;
    border-bottom: 1px solid $sl-line-gray-light;
    justify-content: space-between;
    display: flex;

    span {
      font-size: 1.5rem;
      color: $sl-dark-grey-mobile;
      font-weight: 600;
      margin: 0;

      &:first-child {
        display: block;
      }
      svg {
        width: 24px;
        height: 24px;
        margin: 0;
      }
    }
  }

  .manageTabs {
    padding-top: 142px;

    .existingMember {
      display: none !important;
    }
    .manageDealTeamWork {
      margin: 0 !important;

      .heightMobile {
        height: auto !important;
      }
    }
  }

  .InviteOuterBtn {
    padding: 16px;
    position: fixed !important;
    top: 128px;
    background-color: white;
    z-index: 1;
  }

  .manageParticipantsMobile {
    .manageElements {
      flex-direction: column-reverse;
      width: 100vw;
      background-color: white;
      box-shadow: none;

      .manageParticipants {
        &__tabsContainer {
          padding: 0 16px !important;
        }
      }
    }
  }
  .contentMemberList {
    padding: 0 !important;
    margin-top: 60px;
  }
  .manageParticipantsWrapper__show {
    width: 100vw;
  }
}

.counterpartyPanel {
  @media only screen and (max-width: $mobile-width) {
    max-width: inherit !important;
  }
}

.counterpartyItem {
  background-color: $sl-aliceblue-tint;
  margin-left: -15px;
  margin-right: -15px;
  padding: 12px 16px;
  margin-bottom: 16px;

  &__name {
    font-weight: bold;
    color: $sl-dark-grey-mobile;
  }

  &__email {
    display: flex;
    margin-top: 4px;
    
    &__title {
      color: $sl-gray-label;
    }
    
    &__content {
      margin-left: 4px;
      color: $sl-dark-grey-mobile;
    }
  }
}