@import 'src/styles/_colors.scss';

.wrapper {
  padding-bottom: 12px;
  display: flex;
}

.title {
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 15px;
  color: $sl-grey;
}

.buttons {
  justify-content: right;
  margin-left: 8px !important;
  margin-right: 0px !important;

  button {
    padding: 5px 9px 6px 9px;
  }

  &-add:disabled {
    opacity: 0.5 !important;
  }
}
