@import '../../styles/bootstrap-variables';
@import '../../styles/colors';

.dealItem {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  background-color: $sl-aliceblue-tint;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    width: calc(100% - 100px);
    color: $sl-dark-grey-mobile;
  }
}

.docItem {
  padding: 16px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  background-color: $sl-aliceblue-tint;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    width: calc(100% - 100px);
    color: $sl-dark-grey-mobile;
  }

  &__creator,
  &__updatedAt {
    width: 50%;
  }
}

.dealTable {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
