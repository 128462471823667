@import '../../../../../../styles/colors';
@import '../../../../../../styles/bootstrap-variables';

//
// Card styles

.card {
  //background: linear-gradient(145deg, white 0 50%, rgba(white, 0.92) 75%, rgba(white, 0.88)),

  border-radius: $border-radius;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: $spacer * 2;
  min-height: 385px;
  overflow: auto;
  padding: $spacer * 0.75 $spacer * 0.75;
  padding-right: 0;
  position: relative;

  &:hover,
  &:focus {
    .cardIcon {
      display: block;
    }
  }
}

.cardFullHeight {
  height: calc(100vh - 225px);
  margin: 0px -15px;
  padding-right: 15px;
}

.cardTitle {
  font-size: $font-size-nominal-default;
  margin-bottom: $spacer * 1.5;
}

// Activity Card styles

.activityCardItem {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid $sl-border-grey;
  padding: 0.5rem;
  justify-content: space-between;
  border-style: none solid;
  border-width: 4px;
  border-color: transparent;
  display: block;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid $sl-border-grey;
  background-color: white;
  color: $sl-dark-deep-grey;
  margin-bottom: 10px;
  border-radius: 6px;

  .activityActorAndDate {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
  }

  .activityDate {
    align-items: flex-end;
  }

  .activityTitle {
    font-size: 15px;
    display: flex;
    align-items: center;
    padding-bottom: 8px;

    .userAvatar {
      font-weight: 700;
    }
  }

  .clock {
    margin-bottom: $spacer * 0.125;
    margin-right: $spacer * 0.25;
  }

  .activityActor {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 105px;
    font-weight: 500;
    display: inline-block;
  }

  &:hover {
    background-color: $sl-table-row-onhover;
  }
}

.activityCardItem__selected {
  background-color: $sl-table-row-onhover;
  border-left-color: $sl-ocean-blue;
  cursor: pointer;
}

.suggestionsData {
  padding-top: 5px;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;

  &__name {
    font-weight: 500;
  }
}

.profileHeader {
  display: flex;
  align-items: center;
  margin-bottom: $spacer * 2;

  h4 {
    margin-bottom: 0;
    margin-left: $spacer;
  }
}

.navigationContainer {
  padding: 0rem 1.25rem $spacer 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  button {
    font-size: $font-size-nominal-default * 0.75;
    padding: 3px 10px;
  }
}

//loader
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 6px;
}
.lds-ellipsis div {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: $sl-border-grey;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 32px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(18px, 0);
  }
}

.userAvatar {
  margin-right: $spacer/2;
  display: inline-flex;
  border: 2px solid $sl-ocean-blue;
  border-radius: 50%;
}
