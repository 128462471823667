@import '../../../../../styles/colors';
@import '../../../../../styles/bootstrap-variables';


.placeholderWarningModal {
  z-index: 1110 !important;

  &__header {
    text-align: center;
  }
  &__title {
    margin: $spacer 0;
    font-size: $spacer;
    text-align: center;
  }
  &__buttonsContainer {
    display: flex;
    justify-content: center;
    margin-top: $spacer * 2;
  }
  &__buttonReview{
    margin-right: $spacer;
  }
}